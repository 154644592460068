import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "axios";

const SponsersForm = () => {
  const { t, i18n } = useTranslation();

  const validationSchema = Yup.object({
    first_name: Yup.string().required(t("common.firstNameValidation")),
    last_name: Yup.string().required(t("common.lastNameValidation")),
    email: Yup.string().email("").required(t("common.emailValidation")),
    job_title: Yup.string().required(t("common.jopTitleValidation")),
    phone: Yup.string()
      .matches(/^05\d{8}$/, t("common.phoneValidation1"))
      .required(t("common.phoneValidation2")),
    company: Yup.string().required(t("common.companyValidation")),
  });

  const handleSave = async (values) => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
      const formData = new FormData();

      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          formData.append(key, values[key]);
        }
      }
      axios
        .post("https://admin.fmexcon.com/api/sponsorRegisterStore", formData)
        .then((res) => {
          toast.success(res?.data?.message);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error("Validation errors:", err.errors);
    }
  };

  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        email: "",
        company: "",
        job_title: "",
        phone: "",
        website_url: "",
        message: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, formikBag) => {
        formikBag.setErrors({});
      }}
    >
      {({
        handleSubmit,
        errors,
        touched,
        values,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit} className="w-full max-w-2xl mx-auto p-4">
          <div className="flex flex-col mb-4">
            <label>
              {t("sponsor.emailAddress")}
              <span className="text-red-700 text-xl">*</span>
            </label>
            <Field
              name="email"
              type="email"
              className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
              placeholder={t("sponsor.emailAddress")}
              value={values.email}
              onChange={(e) => {
                setFieldValue("email", e.target.value);
              }}
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label>
                {t("sponsor.firstName")}{" "}
                <span className="text-red-700 text-xl">*</span>
              </label>
              <Field
                name="first_name"
                className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
                placeholder={t("sponsor.firstName")}
                value={values.first_name}
                onChange={(e) => {
                  setFieldValue("first_name", e.target.value);
                }}
              />
              <ErrorMessage
                name="first_name"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div className="flex flex-col">
              <label>
                {t("sponsor.lastName")}{" "}
                <span className="text-red-700 text-xl">*</span>
              </label>
              <Field
                name="last_name"
                className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
                placeholder={t("sponsor.lastName")}
                value={values.last_name}
                onChange={(e) => {
                  setFieldValue("last_name", e.target.value);
                }}
              />
              <ErrorMessage
                name="last_name"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label>
                {t("sponsor.company")}{" "}
                <span className="text-red-700 text-xl">*</span>
              </label>
              <Field
                name="company"
                className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
                placeholder={t("sponsor.company")}
                value={values.company}
                onChange={(e) => {
                  setFieldValue("company", e.target.value);
                }}
              />
              <ErrorMessage
                name="company"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div className="flex flex-col">
              <label>
                {t("sponsor.jobTitle")}{" "}
                <span className="text-red-700 text-xl">*</span>
              </label>
              <Field
                name="job_title"
                className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
                placeholder={t("sponsor.jobTitle")}
                value={values.job_title}
                onChange={(e) => {
                  setFieldValue("job_title", e.target.value);
                }}
              />
              <ErrorMessage
                name="job_title"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="mt-1">{t("sponsor.webSiteUrl")} </label>
              <Field
                name="website_url"
                className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
                placeholder={t("sponsor.webSiteUrl")}
                value={values.website_url}
                onChange={(e) => {
                  setFieldValue("website_url", e.target.value);
                }}
              />
              <ErrorMessage
                name="website_url"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div className="flex flex-col">
              <label>
                {t("sponsor.phoneNumber")}{" "}
                <span className="text-red-700 text-xl">*</span>
              </label>
              <Field
                name="phone"
                className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
                placeholder={t("sponsor.phoneNumber")}
                value={values.phone}
                onChange={(e) => {
                  setFieldValue("phone", e.target.value);
                }}
              />
              <ErrorMessage
                name="phone"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>
          <div className="flex flex-col mb-4">
            <label>{t("sponsor.comment")}</label>
            <Field
              as="textarea"
              name="message"
              className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
              placeholder="Message"
              rows="4"
            />
            <ErrorMessage
              name="message"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="px-6 py-2 bg-[#44add2] text-white rounded-lg hover:bg-[#319cc4]"
              onClick={() => {
                handleSave(values);
              }}
              disabled
            >
              {t("sponsor.registerWithUs")}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SponsersForm;
