import React, { useState } from "react";
import "./Schedule.css";
import day1 from "../../Images/اليوم-الاول.png";
import day2 from "../../Images/اليوم-الثاني.png";
import day3 from "../../Images/اليوم-الثالث.png";
import day1E from "../../Images/day-1.png";
import day2E from "../../Images/day-2.png";
import day3E from "../../Images/day-3.png";
import { useTranslation } from "react-i18next";

const Schedule = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const { t, i18n } = useTranslation();

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <section className=" mx-auto lg:px-20 md:px-20 px-4  w-full">
      <button
        type="submit"
        onClick={() => {
          // handleSave(values);
        }}
        className="block py-3 px-6 lg:w-fit md:w-fit w-full bg-[#44add2] hover:bg-[#319cc4] rounded-md text-white font-bold mb-6 mx-auto lg:text-xl md:text-xl"
      >
        <a
          href={
            i18n.language == "en"
              ? "/files/ConferenceCalendar.pdf"
              : "/files/جدول المواعيد.pdf"
          }
          download
        >
          {t("conference.downloadTheSchedule")}
        </a>
      </button>
      <div className="flex border-b border-gray-200 w-full">
        <button
          className={`flex-1 px-4 py-3 text-center w-1/3 lg:text-xl md:text-xl  ${
            activeTab === "tab1"
              ? "bg-[#44add2] text-white lg:font-semibold md:font-semibold"
              : "bg-gray-200 text-gray-700"
          } rounded-t-lg focus:outline-none`}
          onClick={() => handleTabClick("tab1")}
        >
          {t("conference.day1")}
        </button>
        <button
          className={`flex-1 px-4 py-3 text-center w-1/3 lg:text-xl md:text-xl  ${
            activeTab === "tab2"
              ? "bg-[#44add2] text-white lg:font-semibold md:font-semibold"
              : "bg-gray-200 text-gray-700"
          } rounded-t-lg focus:outline-none`}
          onClick={() => handleTabClick("tab2")}
        >
          {t("conference.day2")}
        </button>
        <button
          className={`flex-1 px-4 py-3 text-center w-1/3 lg:text-xl md:text-xl  ${
            activeTab === "tab3"
              ? "bg-[#44add2] text-white lg:font-semibold md:font-semibold"
              : "bg-gray-200 text-gray-700"
          } rounded-t-lg focus:outline-none`}
          onClick={() => handleTabClick("tab3")}
        >
          {t("conference.day3")}
        </button>
      </div>

      <div className="mt-2">
        <div
          className={`p-4 bg-white rounded-b-lg shadow-md ${
            activeTab === "tab1" ? "block" : "hidden"
          }`}
        >
          <img
            src={i18n.language == "en" ? day1E : day1}
            alt="day1"
            className="mx-auto"
          />
        </div>
        <div
          className={`p-4 bg-white rounded-b-lg shadow-md ${
            activeTab === "tab2" ? "block" : "hidden"
          }`}
        >
          <img src={i18n.language == "en" ? day2E : day2} alt="day2" />
        </div>
        <div
          className={`p-4 bg-white rounded-b-lg shadow-md ${
            activeTab === "tab3" ? "block" : "hidden"
          }`}
        >
          <img
            src={i18n.language == "en" ? day3E : day3}
            alt="day3"
            className="w-full"
          />
        </div>
      </div>
    </section>
  );
};

export default Schedule;
