import React from "react";
import { useTranslation } from "react-i18next";
import "./VisitSaudiCompany.css";

const VisitSaudiCompany = () => {
  const { t, i18n } = useTranslation();

  return (
    <section
      className="visit-saudi-company"
      dir={i18n.language == "en" ? "ltr" : "rtl"}
    >
      <div
        className={`lg:px-16 md:px-16 px-5 ${
          i18n.language == "en" ? "text-left" : "text-right"
        }`}
      >
        <div className="lg:grid md:grid grid-cols-12 gap-6 mx-auto w-fit">
          <div className=" lg:col-span-6 md:col-span-6 col-span-12 lg:px-16 md:px-16 px-5 my-auto text-white ">
            <p
              style={{
                fontWeight: "bold",
              }}
              className="text-3xl"
            >
              {t("home.sec9Title")}
            </p>
          </div>
          <div className=" lg:col-span-6 md:col-span-6 col-span-12 lg:block md:block flex justify-center lg:mt-0 md:mt-0 mt-4">
            <button className="visit-saudi-company-button bg-white hover:bg-transparent">
              <a href={"https://www.visitsaudi.com/"} target="_blank">
                {t("home.buttonComny")}{" "}
              </a>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VisitSaudiCompany;
