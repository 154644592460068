import React, { useContext, useEffect, useRef, useState } from "react";
import bgImage from "../../Images/ID-المرافق-06.png";
import eventLogo from "../../Images/شعار-المؤتمر.png";
import saryImage from "../../Images/ساري-01.png";
import footerImages from "../../Images/footer-imgs.png";
import fairmontImage from "../../Images/ID-المرافق-03.png";
import dateImage from "../../Images/ID-المرافق-04.png";
import QrImg from "../../Images/QrImg.jpeg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf/dist/jspdf.umd.min";
import { GeneralContext } from "../../Context/GeneralContext";
import QRCode from "qrcode";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Badg = ({ searchId }) => {
  const { t, i18n } = useTranslation();
  const pdfRef = useRef();
  const [qrCodeData, setQRCodeData] = useState("");
  const [badgeData, setBadgeData] = useState();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { id } = useParams();
  console.log(searchId);
  const getRegisterData = async (values) => {
    setLoading(true);
    try {
      axios
        .get(`https://admin.fmexcon.com/api/getTicket/${id ? id : searchId}`)
        .then((res) => {
          setLoading(false);
          setBadgeData(res?.data?.user);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    } catch (err) {
      console.error("Validation errors:", err.errors);
    }
  };

  const confirmAttending = async () => {
    setButtonLoading(true);
    const today = new Date();
    const day = today.getDate();
    const currentDay = day == 8 ? 1 : day == 9 ? 2 : day == 10 ? 3 : 1;

    try {
      axios
        .get("https://admin.fmexcon.com/api/attendUser", {
          params: {
            id: badgeData?.id,
            day: currentDay,
          },
        })
        .then((res) => {
          setButtonLoading(false);
          toast.success("تم تأكيد الحضور بنجاح");
        });
    } catch (err) {
      setButtonLoading(false);
      console.error("Error fetching badge data:", err);
    }
  };

  useEffect(() => {
    getRegisterData();
  }, []);

  const downloadPdf = () => {
    const input = pdfRef.current;

    // Increase the scale factor for better image quality
    html2canvas(input, { scale: 4, useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Create a new jsPDF instance with the desired page size
      const pdf = new jsPDF("p", "mm", "a4");

      // Get the PDF page size in mm
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Convert canvas dimensions from pixels to mm
      const imgWidth = canvas.width * 0.264583; // convert pixels to mm
      const imgHeight = canvas.height * 0.264583; // convert pixels to mm

      // Calculate the scaling ratio to fit the image within the PDF page width
      const ratio = pdfWidth / imgWidth;

      // Calculate the new image dimensions to fit the full width of the PDF page
      const scaledImgWidth = pdfWidth;
      const scaledImgHeight = imgHeight * ratio;

      // Calculate the vertical position to center the image on the PDF page
      const imgX = 0; // Align to the left edge of the PDF
      const imgY = (pdfHeight - scaledImgHeight) / 2; // Center vertically

      // Add the image to the PDF with high quality settings
      pdf.addImage(imgData, "PNG", imgX, imgY, scaledImgWidth, scaledImgHeight);

      // Save the generated PDF
      pdf.save("badge.pdf");
    });
  };

  const generateQRCode = () => {
    console.log(badgeData?.phone);
    QRCode.toDataURL(
      `https://wa.me/+966${badgeData?.phone?.slice(1)}`,
      { width: 300, margin: 2 },
      (err, url) => {
        if (err) {
          console.error("Error generating QR code", err);
          return;
        }
        setQRCodeData(url);
      }
    );
  };

  useEffect(() => {
    generateQRCode();
  }, [badgeData]);

  return (
    <section className="badg">
      <div className="lg:flex md:flex sm:flex justify-center items-center  gap-3 mt-10 px-16">
        <p className="text-[#dc3545] font-semibold text-sm">
          {" "}
          Screenshot or download PDF*
        </p>
        <div>
          <button
            type="submit"
            onClick={downloadPdf}
            style={{ backgroundColor: "#44add2" }}
            className="py-2 px-4 lg:w-fit md:w-fit w-full rounded-md text-white font-bold text-sm"
          >
            Download as PDF
          </button>
        </div>
      </div>
      {loading ? (
        <div className="lg:grid md:grid grid-cols-12 gap-6 mx-auto w-fit h-full mt-6">
          <div className="bg-white shadow-lg rounded-lg p-6 my-8 box col-span-12 w-[340px] h-[491px] animate-pulse"></div>
        </div>
      ) : (
        <div>
          <div
            className="badge-card relative mx-auto border mt-7 mb-10"
            style={{
              width: "340.1574803149606px",
              height: "491.33858267716533px",
            }}
            ref={pdfRef}
          >
            <img
              src={bgImage}
              alt="bgImage"
              className="absolute top-0 left-0 w-full h-full -z-20"
            />
            <div className="flex ">
              <div
                className="w-2/3 h-1/5 bg-[#0E3352]"
                style={{ borderRadius: "0 0 138px 0" }}
              >
                <img
                  src={eventLogo}
                  alt="eventLogo"
                  className="w-2/3 p-2 translate-x-4"
                />
              </div>
              <img
                src={saryImage}
                alt="saryImage"
                className=" p-2 translate-x-4 h-20 mt-3"
              />
            </div>
            <div className="font-semibold mt-7">
              <p> {badgeData?.name ? badgeData?.name : "position"}</p>
              <p className="text-[#44add2] my-1">
                {badgeData?.position ? badgeData?.position : "position"}
              </p>
              <p>{badgeData?.job_title ? badgeData?.job_title : "job_title"}</p>
            </div>
            <div
              className="font-semibold h-24 w-56 bg-[#0E3352] my-6 mx-auto flex justify-between items-center"
              style={{ borderRadius: "0 20px 20px 0" }}
            >
              <div className="h-24 bg-white">
                {qrCodeData && (
                  <img
                    src={qrCodeData}
                    alt="Generated QR Code"
                    className=" h-24 p-[1px]"
                  />
                )}
              </div>
              <div
                className="text-center text-white"
                style={{ width: "calc(100% - 6rem)" }}
              >
                <p
                  style={{
                    fontWeight: "semibold",
                    fontSize: "21px",
                  }}
                >
                  زائر
                </p>
                <p
                  style={{
                    letterSpacing: "3.5px",
                    fontWeight: "semibold",
                    fontSize: "21px",
                  }}
                >
                  Visitor
                </p>
              </div>
            </div>
            <div className="flex justify-around items-center mb-4">
              <img
                src={fairmontImage}
                alt="fairmontImage"
                className=" p-2 h-12 mt-3"
              />
              <img src={dateImage} alt="dateImage" className=" p-2 h-12 mt-3" />
            </div>
            <div style={{ borderTop: "1.2px solid #0E3352" }}>
              <img
                src={footerImages}
                alt="footerImages"
                className=" pb-4 px-3 mt-4 "
              />
            </div>
          </div>
          {searchId &&
            (buttonLoading ? (
              <div class="flex items-center justify-center mb-10 -mt-4">
                <div class="animate-spin rounded-full h-6 w-6 border-t-4 border-b-4 border-[#44add2]"></div>
              </div>
            ) : (
              <div
                className="rounded-lg w-fit bg-[#44add2] hover:bg-[#319cc4] flex items-center justify-center lg:px-10 md:px-10 px-5 py-2 cursor-pointer mb-10 -mt-4 mx-auto"
                onClick={confirmAttending}
              >
                <p className="text-white font-bold lg:text-lg md:text-lg text-sm">
                  {t("conference.confirm")}
                </p>
              </div>
            ))}
        </div>
      )}
    </section>
  );
};

export default Badg;
