import React from "react";
import "./Sponser.css";
import { useTranslation } from "react-i18next";
import StrategicSponsor from "../../Images/Strategic-Sponsor-1.png";
import MainSponsor from "../../Images/Main-Sponsor.png";
import DiamondSponsor1 from "../../Images/Diamond-Sponsor-1.png";
import DiamondSponsor2 from "../../Images/Diamond-Sponsor-2.png";
import DiamondSponsor3 from "../../Images/Diamond-Sponsor-3.png";
import DiamondSponsor4 from "../../Images/Diamond-Sponsor-4.png";
import DiamondSponsor5 from "../../Images/Diamond-Sponsor-5.png";
import DiamondSponsor6 from "../../Images/Diamond-Sponsor-6.png";
import DiamondSponsor7 from "../../Images/Diamond-Sponsor-7.jpg";
import DiamondSponsor8 from "../../Images/Diamond-Sponsor-8.png";
import DiamondSponsor9 from "../../Images/Diamond-Sponsor-9.jpeg";
import DiamondSponsor10 from "../../Images/Diamond-Sponsor-10.png";
import GoldenSponsor1 from "../../Images/Golden-Sponser1.png";
import GoldenSponsor2 from "../../Images/Golden-Sponser2.png";
import GoldenSponsor3 from "../../Images/Golden-Sponser3.png";
import GoldenSponsor4 from "../../Images/Golden-Sponser4.png";
import GoldenSponsor5 from "../../Images/Golden-Sponser5.png";
import GoldenSponsor6 from "../../Images/Golden-Sponser6.png";
import GoldenSponsor7 from "../../Images/Golden-Sponser7.png";
import GoldenSponsor8 from "../../Images/Golden-Sponser8.png";
import GoldenSponsor9 from "../../Images/Golden-Sponser9.png";
import GoldenSponsor10 from "../../Images/Golden-Sponser10.png";
import GoldenSponsor11 from "../../Images/Golden-Sponser11.png";
import GoldenSponsor12 from "../../Images/Golden-Sponser12.png";
import GoldenSponsor13 from "../../Images/Golden-Sponser13.png";
import GoldenSponsor14 from "../../Images/Golden-Sponser14.png";
import GoldenSponsor15 from "../../Images/Golden-Sponser15.png";
import GoldenSponsor16 from "../../Images/Golden-Sponser16.png";
import GoldenSponsor17 from "../../Images/Golden-Sponser17.png";
import GoldenSponsor18 from "../../Images/Golden-Sponser18.png";
import SupportingPartners1 from "../../Images/Supporting-Partners1.png";
import SupportingPartners2 from "../../Images/Supporting-Partners2.png";
import SupportingPartners3 from "../../Images/Supporting-Partners3.png";
import SupportingPartners4 from "../../Images/Supporting-Partners4.png";
import SupportingPartners5 from "../../Images/Supporting-Partners5.png";
import SupportingPartners6 from "../../Images/Supporting-Partners6.png";
import SupportingPartners7 from "../../Images/Supporting-Partners7.png";
import SupportingPartners8 from "../../Images/Supporting-Partners8.png";
import SupportingPartners9 from "../../Images/Supporting-Partners9.png";
import SupportingPartners10 from "../../Images/Supporting-Partners10.png";
import SupportingPartners11 from "../../Images/Supporting-Partners11.png";
import SupportingPartners12 from "../../Images/Supporting-Partners11.png";
import Carousel from "../../Components/Carousel/Carousel";
import GoldenSponserCarousel from "../GoldenSponserCarousel/GoldenSponserCarousel";
const Sponser = () => {
  const { t, i18n } = useTranslation();
  const GoldenSponsers = [
    { imgSrc: GoldenSponsor1, name: "DiamondSponsor1", count: 53 },
    { imgSrc: GoldenSponsor2, name: "DiamondSponsor1", count: 58 },
    { imgSrc: GoldenSponsor3, name: "DiamondSponsor1", count: 68 },
    { imgSrc: GoldenSponsor4, name: "DiamondSponsor1", count: 74 },
    { imgSrc: GoldenSponsor5, name: "DiamondSponsor1", count: 20 },
    { imgSrc: GoldenSponsor6, name: "DiamondSponsor1", count: 29 },
    { imgSrc: GoldenSponsor3, name: "DiamondSponsor1", count: 68 },
    { imgSrc: GoldenSponsor7, name: "DiamondSponsor1", count: 32 },
    { imgSrc: GoldenSponsor8, name: "DiamondSponsor1", count: 56 },
    { imgSrc: GoldenSponsor9, name: "DiamondSponsor1", count: 26 },
    { imgSrc: GoldenSponsor10, name: "DiamondSponsor1", count: 88 },
    { imgSrc: GoldenSponsor11, name: "DiamondSponsor1", count: 47 },
    { imgSrc: GoldenSponsor12, name: "DiamondSponsor1", count: 94 },
    { imgSrc: GoldenSponsor13, name: "DiamondSponsor1", count: 84 },
    { imgSrc: GoldenSponsor14, name: "DiamondSponsor1", count: 42 },
    { imgSrc: GoldenSponsor15, name: "DiamondSponsor1", count: 43 },
    { imgSrc: GoldenSponsor16, name: "DiamondSponsor1", count: 44 },
    { imgSrc: GoldenSponsor17, name: "DiamondSponsor1", count: 45 },
    { imgSrc: GoldenSponsor18, name: "DiamondSponsor1", count: 46 },
  ];
  const SupportingPartners = [
    { imgSrc: SupportingPartners1, name: "DiamondSponsor1", count: 22 },
    { imgSrc: SupportingPartners2, name: "DiamondSponsor1", count: 44 },
    { imgSrc: SupportingPartners3, name: "DiamondSponsor1", count: 55 },
    { imgSrc: SupportingPartners4, name: "DiamondSponsor1", count: 66 },
    { imgSrc: SupportingPartners5, name: "DiamondSponsor1", count: 77 },
    { imgSrc: SupportingPartners6, name: "DiamondSponsor1", count: 46 },
    { imgSrc: SupportingPartners7, name: "DiamondSponsor1", count: 89 },
    { imgSrc: SupportingPartners8, name: "DiamondSponsor1", count: 54 },
    { imgSrc: SupportingPartners9, name: "DiamondSponsor1", count: 43 },
    { imgSrc: SupportingPartners10, name: "DiamondSponsor1", count: 90 },
    { imgSrc: SupportingPartners11, name: "DiamondSponsor1", count: 91 },
    { imgSrc: SupportingPartners12, name: "DiamondSponsor1", count: 92 },
  ];

  return (
    <section className="mb-40" dir={i18n.language == "en" ? "ltr" : "rtl"}>
      <p
        style={{
          fontWeight: "bold",
          color: "#44add2",
        }}
        className={` lg:mx-16 mx-4 text-3xl my-8 ${
          i18n.language == "en"
            ? "lg:text-left md:text-left text-center"
            : "lg:text-right md:text-right text-center"
        }`}
      >
        {t("home.strategicSponsor")}
      </p>
      <div>
        <img
          src={StrategicSponsor}
          className=" mx-auto lg:w-96 md:w-80 w-72"
          alt="minister_image"
        />
      </div>
      <p
        style={{
          fontWeight: "bold",
          color: "#44add2",
        }}
        className={` lg:mx-16 mx-4 text-3xl my-12 ${
          i18n.language == "en"
            ? "lg:text-left md:text-left text-center"
            : "lg:text-right md:text-right text-center"
        }`}
      >
        {t("home.mainSponsor")}
      </p>
      <div>
        <img
          src={MainSponsor}
          className=" mx-auto lg:w-60 md:w-52 w-44 my-8"
          alt="minister_image"
        />
      </div>
      <p
        style={{
          fontWeight: "bold",
          color: "#44add2",
        }}
        className={` lg:mx-16 mx-4 text-3xl my-12 ${
          i18n.language == "en"
            ? "lg:text-left md:text-left text-center"
            : "lg:text-right md:text-right text-center"
        }`}
      >
        {t("home.diamondSponsor")}
      </p>
      <div className=" grid grid-cols-12 lg:gap-8 md:gap-8 sm:gap-8 mx-4">
        <img
          src={DiamondSponsor1}
          className=" mx-auto lg:col-span-4 md:col-span-6 col-span-6 lg:w-56 w-48 lg:my-0 md:my-0 sm:my-0 my-4"
          alt="minister_image"
        />
        <img
          src={DiamondSponsor2}
          className=" mx-auto lg:col-span-4 md:col-span-6 col-span-6 lg:w-56 w-48 lg:my-0 md:my-0 sm:my-0 my-4"
          alt="minister_image"
        />{" "}
        <img
          src={DiamondSponsor3}
          className=" mx-auto lg:col-span-4 md:col-span-12 col-span-12 lg:p-0 p-6 lg:w-56 w-48 lg:my-0 md:my-0 sm:my-0 my-4"
          alt="minister_image"
        />
      </div>
      <div className=" grid grid-cols-12 lg:gap-8 md:gap-8 sm:gap-8 mx-4 lg:my-8">
        <img
          src={DiamondSponsor8}
          className=" mx-auto lg:col-span-4 md:col-span-6 col-span-6 lg:w-56 w-48 lg:my-0 md:my-0 sm:my-0 my-4"
          alt="minister_image"
        />
        <img
          src={DiamondSponsor5}
          className=" mx-auto lg:col-span-4 md:col-span-6 col-span-6 lg:w-56 w-48 lg:my-0 md:my-0 sm:my-0 my-4"
          alt="minister_image"
        />{" "}
        <img
          src={DiamondSponsor6}
          className=" mx-auto lg:col-span-4 md:col-span-12 col-span-12 lg:p-0 p-6 lg:w-56 w-48 lg:my-0 md:my-0 sm:my-0 my-4"
          alt="minister_image"
        />
      </div>
      <div className=" grid grid-cols-12 lg:gap-8 md:gap-8 sm:gap-8 mx-4">
        <img
          src={DiamondSponsor10}
          className=" mx-auto lg:col-span-4 md:col-span-6 col-span-6 lg:w-72 w-56 lg:my-0 md:my-0 sm:my-0 my-4"
          alt="minister_image"
        />
        <img
          src={DiamondSponsor4}
          className=" mx-auto lg:col-span-4 md:col-span-6 col-span-6 lg:w-56 w-48 lg:my-0 md:my-0 sm:my-0 my-4"
          alt="minister_image"
        />{" "}
        <img
          src={DiamondSponsor9}
          className=" mx-auto lg:col-span-4 md:col-span-12 col-span-12 lg:p-0 p-6  lg:w-52 w-44 lg:my-0 md:my-0 sm:my-0 my-4"
          alt="minister_image"
        />
      </div>
      <div className=" grid grid-cols-12 lg:gap-8 md:gap-8 sm:gap-8 mx-4">
        <img
          src={DiamondSponsor7}
          className=" mx-auto lg:col-span-12 md:col-span-12 col-span-12 lg:p-0 p-6 lg:w-56 w-48 lg:my-0 md:my-0 sm:my-0"
          alt="minister_image"
        />
      </div>

      <p
        style={{
          fontWeight: "bold",
          color: "#44add2",
        }}
        className={` lg:mx-16 mx-4 text-3xl my-8 ${
          i18n.language == "en"
            ? "lg:text-left md:text-left text-center"
            : "lg:text-right md:text-right text-center"
        }`}
      >
        {t("home.goldenSponsor")}
      </p>
      <GoldenSponserCarousel sponsers={GoldenSponsers} />
      <p
        style={{
          fontWeight: "bold",
          color: "#44add2",
          marginTop: "100px",
        }}
        className={` mx-16 text-3xl  ${
          i18n.language == "en"
            ? "lg:text-left md:text-left text-center"
            : "lg:text-right md:text-right text-center"
        }`}
      >
        {t("home.supportivePartners")}
      </p>
      <div className="mb-32">
        <Carousel sponsers={SupportingPartners} />
      </div>
    </section>
  );
};

export default Sponser;
