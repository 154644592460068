import React, { useEffect } from "react";
import "./Sponsers.css";
import aboutImage from "../../Images/aboutImage.png";
import sponsersImage1 from "../../Images/sponsers-image1.png";
import sponsersImage2 from "../../Images/sponsers-image2.png";
import sponsersImage3 from "../../Images/sponsers-image3.png";
import WhyThisEvent1 from "../../Images/WhyThisEvent1.png";
import WhyThisEvent2 from "../../Images/WhyThisEvent2.png";
import WhyThisEvent3 from "../../Images/WhyThisEvent3.png";

import { useTranslation } from "react-i18next";
import RegisterWithUs from "../../Components/RegisterWithUs/RegisterWithUs";
import GetNewUpdates from "../../Components/GetNewUpdates/GetNewUpdates";
import EventDetails from "../../Components/EventDetails/EventDetails";
import Sponser from "../../Components/Sponser/Sponser";
import MapSection from "../../Components/MapSection/MapSection";
import SponsersForm from "../../Components/SponsersForm/SponsersForm";
import ScrollToAnchor from "../../helpers/ScrollToAnchor";

const Sponsers = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    document.title = "Sponsor";
  }, []);

  return (
    <section
      className="about grid grid-cols-12 gap-5 text-center"
      dir={i18n.language == "en" ? "ltr" : "rtl"}
      style={{ textAlign: i18n.language == "en" ? "left" : "right" }}
    >
      <ScrollToAnchor />
      <div className=" lg:col-span-12 col-span-12  back-ground">
        <img
          src={aboutImage}
          className="about-img col-span-12"
          alt="aboutImage"
        />
        <div className="text text-center">
          <h3> {t("sponsor.sponsor")}</h3>
          <p className="mt-6 text-white">powerd by SFMA</p>
        </div>
      </div>
      <div className="col-span-12 -mt-5" style={{ backgroundColor: "#f5f5f5" }}>
        <div className="col-span-12">
          <GetNewUpdates fromSponsers={true} />
        </div>
        <div className="col-span-12">
          <div className="lg:grid md:grid grid-cols-12 lg:px-16 md:px-16 px-4 mb-10 -mt-6">
            <div
              className={`lg:col-span-5 md:col-span-5 col-span-12 px-3   ${
                i18n.language == "en" ? "text-left" : "text-right"
              }`}
            >
              <p
                style={{
                  color: "#103783",
                  fontWeight: "bold",
                  fontSize: "30px",
                }}
              >
                {t("sponsor.p1")}
              </p>
            </div>
            <div className={`lg:col-span-7 md:col-span-7 col-span-12 mx-4`}>
              <p className={`text-sm leading-loose text-justify`}>
                {t("sponsor.p2")}
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 lg:mx-16 md:mx-16 px-4 mb-16 gap-8">
          <div className="card bg-white shadow-lg p-4 rounded-xl">
            <div className="flex items-center mb-4">
              <img
                src={sponsersImage1}
                alt="Card 1"
                className="w-16 h-16 object-cover mr-4"
              />
              <h2 className="text-xl font-semibold text-[#44add2]">
                {t("sponsor.card1Title")}
              </h2>
            </div>
            <p className="text-[#212229] px-2 pb-2 text-justify">
              {t("sponsor.card1Content")}
            </p>
          </div>
          <div className="card bg-white shadow-lg p-4 rounded-xl">
            <div className="flex items-center mb-4">
              <img
                src={sponsersImage2}
                alt="Card 2"
                className="w-16 h-16 object-cover mr-4"
              />
              <h2 className="text-xl font-semibold text-[#44add2]">
                {t("sponsor.card2Title")}
              </h2>
            </div>
            <p className="text-[#212229] px-2 pb-2 text-justify">
              {t("sponsor.card2Content")}
            </p>
          </div>
          <div className="card bg-white shadow-lg p-4 rounded-xl">
            <div className="flex items-center mb-4">
              <img
                src={sponsersImage3}
                alt="Card 3"
                className="w-16 h-16 object-cover mr-4"
              />
              <h2 className="text-xl font-semibold text-[#44add2]">
                {t("sponsor.card3Title")}
              </h2>
            </div>
            <p className="text-[#212229] px-2 pb-2 text-justify">
              {t("sponsor.card3Content")}
            </p>
          </div>
        </div>
      </div>
      <div className="col-span-12" dir="rtl">
        <EventDetails />
      </div>
      <div className="col-span-12 ">
        <p
          style={{
            color: "#44add2",
            fontWeight: "bold",
          }}
          className="text-2xl mt-6 mb-2 text-center"
        >
          {t("sponsor.secTitle")}
        </p>
        <p
          style={{
            fontWeight: "bold",
          }}
          className="text-sm text-center text-[#212529] lg:mx-40 md:mx-28 px-4 mb-6 "
        >
          {t("sponsor.secContent")}
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-x-8 col-span-12 lg:px-16 md:px-16 px-4">
          <div className="card p-4">
            <img
              src={WhyThisEvent1}
              alt="Card 1"
              className="mx-auto mb-4 w-full object-cover"
            />
            <h2
              className="text-lg font-bold mb-2"
              style={{
                color: "rgb(68, 173, 210)",
              }}
            >
              {t("sponsor.card4Title")}
            </h2>
            <p
              style={{
                color: "#212529",
                textAlign: "justify",
              }}
            >
              {t("sponsor.card4Content")}
            </p>
          </div>
          <div className="card p-4">
            <img
              src={WhyThisEvent2}
              alt="Card 1"
              className="mx-auto mb-4 w-full object-cover"
            />
            <h2
              className="text-lg font-bold mb-2"
              style={{
                color: "rgb(68, 173, 210)",
              }}
            >
              {t("sponsor.card5Title")}
            </h2>
            <p
              style={{
                color: "#212529",
                textAlign: "justify",
              }}
            >
              {t("sponsor.card5Content")}
            </p>
          </div>
          <div className="card p-4">
            <img
              src={WhyThisEvent3}
              alt="Card 1"
              className="mx-auto mb-4 w-full object-cover"
            />
            <h2
              className="text-lg font-bold mb-2"
              style={{
                color: "rgb(68, 173, 210)",
              }}
            >
              {t("sponsor.card6Title")}
            </h2>
            <p
              style={{
                color: "#212529",
                textAlign: "justify",
              }}
            >
              {t("sponsor.card6Content")}
            </p>
          </div>
        </div>
      </div>
      <div className="col-span-12 mt-4">
        <Sponser />
      </div>

      <div className="col-span-12">
        <RegisterWithUs />
      </div>
      <div className="col-span-12">
        <MapSection />
      </div>
      <div className="col-span-12 mb-6">
        <SponsersForm />
      </div>
    </section>
  );
};

export default Sponsers;
