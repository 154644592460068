import React, { useRef, useState } from "react";
import MapImage from "../../Images/Map.png";
import { useTranslation } from "react-i18next";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const MapSection = () => {
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const mapRef = useRef();

  const downloadPdf = () => {
    const input = mapRef.current;

    // Increase the scale factor for better image quality
    html2canvas(input, { scale: 4, useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Create a new jsPDF instance with the desired page size
      const pdf = new jsPDF("p", "mm", "a4");

      // Get the PDF page size in mm
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Convert canvas dimensions from pixels to mm
      const imgWidth = canvas.width * 0.264583; // convert pixels to mm
      const imgHeight = canvas.height * 0.264583; // convert pixels to mm

      // Calculate the scaling ratio to fit the image within the PDF page width
      const ratio = pdfWidth / imgWidth;

      // Calculate the new image dimensions to fit the full width of the PDF page
      const scaledImgWidth = pdfWidth;
      const scaledImgHeight = imgHeight * ratio;

      // Calculate the vertical position to center the image on the PDF page
      const imgX = 0; // Align to the left edge of the PDF
      const imgY = (pdfHeight - scaledImgHeight) / 2; // Center vertically

      // Add the image to the PDF with high quality settings
      pdf.addImage(imgData, "PNG", imgX, imgY, scaledImgWidth, scaledImgHeight);

      // Save the generated PDF
      pdf.save("Map.pdf");
    });
  };

  return (
    <section className="relative">
      <div className="mb-8">
        <img
          src={MapImage}
          className="lg:w-1/2 md:w-7/12 sm:w-3/4  w-full rounded-xl px-12 mx-auto "
          alt="MapImage"
          onClick={() => setIsModalOpen(true)}
          ref={mapRef}
        />
        <button
          className="block text-center px-7 py-3 bg-[#44add2] hover:bg-[#319cc4] rounded-xl w-fit mx-auto mt-4 text-white cursor-pointer"
          onClick={downloadPdf}
          type="submit"
          id="sponserForm"
        >
          {t("sponsor.downloadMap")}
        </button>
      </div>
      {isModalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 px-6"
          onClick={() => setIsModalOpen(false)}
        >
          <img
            src={MapImage}
            className="lg:w-1/2 md:w-7/12 sm:w-3/4 w-full rounded-xl"
            alt="MapImage"
          />
          <button
            className="absolute top-0 right-0 m-4 text-black text-2xl font-bold"
            onClick={() => setIsModalOpen(false)}
          >
            &times;
          </button>
        </div>
      )}
    </section>
  );
};

export default MapSection;
