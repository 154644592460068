import React from "react";

import "./WhatIsEvent.css";
import { useTranslation } from "react-i18next";

const WhatIsEvent = () => {
  const { t, i18n } = useTranslation();

  return (
    <section
      className="what-is-event lg:px-16 md:16 px-6 mb-20"
      dir={i18n.language == "en" ? "ltr" : "rtl"}
    >
      <div className="lg:grid md:grid grid-cols-12">
        <div
          className={`lg:col-span-3 md:col-span-3 col-span-12 ${
            i18n.language == "en" ? "text-left" : "text-right"
          }`}
        >
          <p
            style={{
              color: "#44add2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            {t("home.p5")}
          </p>
          <p
            style={{
              color: "#103783",
              fontWeight: "bold",
              fontSize: "24px",
            }}
          >
            {t("home.p6")}
          </p>
        </div>
        <div className=" lg:col-span-9 md:col-span-9 col-span-12">
          <p className={`text-sm leading-loose text-justify`}>
            {" "}
            {t("home.p7")}
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhatIsEvent;
