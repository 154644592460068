import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import logoImg from "../../Images/logo-dark.png";
import { useTranslation } from "react-i18next";
import "./Navbar.css";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";

const Navbar = () => {
  const [displayNav, setDisplayNav] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const changeLanguageAction = (lang) => {
    if (location.pathname.includes("/en")) {
      const newPath = location.pathname.replace("/en", "/ar");
      navigate(newPath, { replace: true });
    } else if (location.pathname.includes("/ar")) {
      const newPath = location.pathname.replace("/ar", "/en");
      navigate(newPath, { replace: true });
    }
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
  };

  useEffect(() => {}, [location]);

  return (
    <header
      className="fixed top-0 w-full nav lg:px-16 md:px-16 px-2 shadow-lg"
      dir={i18n.language === "en" ? "ltr" : "rtl"}
    >
      {" "}
      <div className="mx-auto flex justify-between items-center gap-6">
        {/*lg screens nav*/}
        <img
          src={logoImg}
          className="logo lg:col-span-3 md:col-span-3 col-span-6 lg:w-72 md:w-72 sm:w-64 w-48 lg:translate-y-0 md:translate-y-0 sm:translate-y-0 translate-y-[10px]"
          alt="logo"
        />
        <div className="flex justify-between items-center gap-8">
          <ul
            className=" justify-between gap-4 lg:flex hidden text-xs font-semibold nav-list-lg"
            onClick={() => setDisplayNav(false)}
          >
            <li>
              <NavLink
                to={i18n.language === "en" ? "/en/home" : "/ar/home"}
                end
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
              >
                {t("common.main")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={i18n.language === "en" ? "/en/About" : "/ar/About"}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
              >
                {t("common.aboutUS")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={
                  i18n.language === "en" ? "/en/conferance" : "/ar/conferance"
                }
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
              >
                {t("common.conference")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={i18n.language === "en" ? "/en/sponsor" : "/ar/sponsor"}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
              >
                {t("common.sponsors")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={i18n.language === "en" ? "/en/register" : "/ar/register"}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
              >
                {t("common.login")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={i18n.language === "en" ? "/en/price" : "/ar/price"}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
              >
                {t("common.events")}
              </NavLink>
            </li>
          </ul>
          <div className="col-span-3 gap-6 items-center lg:flex hidden">
            {i18n.language === "en" ? (
              <div
                className="rounded-md p-2 w-24 btn lg:block md:block hidden bg-[#44add2] hover:bg-[#319cc4]"
                onClick={() => changeLanguageAction("ar")}
              >
                <p className="text-white font-bold text-xs">العربية</p>
              </div>
            ) : (
              <div
                className="rounded-md p-2 w-24 btn lg:block md:block hidden bg-[#44add2] hover:bg-[#319cc4]"
                onClick={() => changeLanguageAction("en")}
              >
                <p className="text-white font-bold text-xs">English</p>
              </div>
            )}
          </div>
        </div>

        {/*mobile screens nav*/}
        <div
          className="text-black p-3 rounded-md lg:hidden block cursor-pointer"
          onClick={(e) => {
            setDisplayNav(!displayNav);
          }}
        >
          {!displayNav ? (
            <FaBars className="sm:text-2xl text-xl text-black lg:translate-y-0 md:translate-y-0 sm:translate-y-0 translate-y-[10px]" />
          ) : (
            <MdOutlineClose className="text-3xl text-black lg:translate-y-0 md:translate-y-0 sm:translate-y-0 translate-y-[10px]" />
          )}
        </div>
        <ul
          className={` text-black flex flex-col items-center justify-center w-full h-full bg-white nav-list ${
            displayNav ? "Down" : "Up"
          }`}
          onClick={() => setDisplayNav(false)}
        >
          <li className="py-4">
            <Link to="/">{t("common.main")}</Link>
          </li>
          <li className="py-4">
            <Link to={i18n.language === "en" ? "/en/About" : "/ar/About"}>
              {t("common.aboutUS")}
            </Link>
          </li>
          <li className="py-4">
            <Link
              to={i18n.language === "en" ? "/en/conferance" : "/ar/conferance"}
            >
              {t("common.conference")}
            </Link>
          </li>
          <li className="py-4">
            <Link to={i18n.language === "en" ? "/en/sponsor" : "/ar/sponsor"}>
              {t("common.sponsors")}
            </Link>
          </li>
          <li className="py-4">
            <Link to={i18n.language === "en" ? "/en/register" : "/ar/register"}>
              {t("common.login")}
            </Link>
          </li>
          <li className="py-4">
            <Link to={i18n.language === "en" ? "/en/price" : "/ar/price"}>
              {t("common.events")}
            </Link>
          </li>
          <li className="py-4">
            {i18n.language === "en" ? (
              <div
                className="rounded-md p-2 w-24 btn block bg-[#44add2] hover:bg-[#319cc4]"
                onClick={() => changeLanguageAction("ar")}
              >
                <p className="text-white font-bold text-xs">العربية</p>
              </div>
            ) : (
              <div
                className="rounded-md p-2 w-24 btn block bg-[#44add2] hover:bg-[#319cc4]"
                onClick={() => changeLanguageAction("en")}
              >
                <p className="text-white font-bold text-xs">English</p>
              </div>
            )}
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Navbar;
