import React, { useEffect, useState } from "react";
import aboutImage from "../../Images/aboutImage.png";
import { useTranslation } from "react-i18next";
import { FaPhoneAlt } from "react-icons/fa";
import { MdLocationOn, MdOutlineMailOutline } from "react-icons/md";
import { FaInternetExplorer } from "react-icons/fa";
import RegisterForm from "../../Components/RegisterForm/RegisterForm";
import ScrollToAnchor from "../../helpers/ScrollToAnchor";
import { FaRegCalendarAlt } from "react-icons/fa";
import { BsClockFill } from "react-icons/bs";

const ConferanceLogin = () => {
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    document.title = "Register";
  }, []);

  return (
    <section
      className={`about grid grid-cols-12 gap-5 ${
        i18n.language == "en" ? "text-left" : "text-right"
      }`}
      dir={i18n.language == "en" ? "ltr" : "rtl"}
    >
      <ScrollToAnchor />

      {isModalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 px-12 py-24 text-center"
          onClick={() => setIsModalOpen(false)}
        >
          <div className="bg-white lg:w-2/3 md:w-2/3 w-full h-fit mt-20 py-12">
            <p className="text-3xl ">{t("Register.TermsAndConditions")}</p>
            <p className="text-sm my-4 px-6">
              مرحباً بكم في المؤتمر والمعرض الدولي لإدارة المرافق لعام 2024.
            </p>
            <p className="text-sm lg:px-12 px-6">
              {" "}
              يرجى العلم بأنه عند إكمال عملية التسجيل في الحدث، يرجى ملاحظة أن
              إدارة المؤتمر من الممكن أن تشارك معلومات الاتصال الأساسية الخاصة
              بك والمسجلة في الموقع مع الحاضرين الآخرين في المؤتمر والعارضين
              والجهات الراعية لتسهيل التواصل والعارضين في هذا الحدث. ومن خلال
              المشاركة في هذا الحدث، فإنك توافق على أنه يجوز تصويرك أو ظهورك
              أثناء البث المباشر وتمنح إدارة المؤتمر او الشركة المنظمة الحق في
              استخدام ونشر وحقوق الطبع والنشر لصورتك و/أو ما شابه ذلك و/أو اسمك
              في مقاطع الفيديو و/أو الصور الملتقطة خلال هذا الحدث. للاستخدام من
              قبل إدارة المؤتمر أو الشركة المنظمة في جميع أنحاء العالم، إلى
              الأبد وفي جميع وسائل الإعلام.
            </p>
          </div>
          <button
            className="absolute top-0 right-0 m-4 text-black text-2xl font-bold"
            onClick={() => setIsModalOpen(false)}
          >
            &times;
          </button>
        </div>
      )}

      <div className=" lg:col-span-12 col-span-12  back-ground">
        <img
          src={aboutImage}
          className="about-img col-span-12"
          alt="aboutImage"
        />
        <div className="text text-center lg:-mt-4 md:-mt-4 -mt-8 ">
          <h3 style={{ lineHeight: "1.2" }}>
            {t("Register.register&contact")}
          </h3>
          <p className="mt-8 text-white">powerd by SFMA</p>
        </div>
      </div>

      <div className="col-span-12">
        <p className="px-4 font-semibold text-lg translate-y-6">
          {t("Register.baiscInformation")}
        </p>
        <div className="lg:grid md:grid grid-cols-12 gap-6 ">
          <div className="lg:col-span-5 translate-y-6 md:col-span-5 col-span-12 h-full">
            <div className=" md:flex md:justify-start md:gap-x-2 sm:flex sm:justify-around sm:gap-x-2 mx-4">
              <div className="w-full ">
                <div className="my-4">
                  <div className="flex flex-col items-center justify-center bg-gray-200 rounded-lg h-32 p-4 flex-0 lg:flex-1 w-full text-center">
                    <FaPhoneAlt className="text-2xl text-[#44add2] my-2" />
                    <p className="text-sm">966565194446+</p>
                    <p className="text-sm">966554222273+</p>
                  </div>
                </div>
                <div className="my-4">
                  <div className="flex flex-col items-center justify-center bg-gray-200 rounded-lg h-32 p-4 flex-0 lg:flex-1 w-full text-center">
                    <FaInternetExplorer className="text-3xl text-[#44add2] my-2" />
                    <p className="text-sm">sfma.sa</p>
                  </div>
                </div>
                <div className="my-4 lg:block md:block sm:block hidden">
                  <div className="flex flex-col items-center justify-center bg-gray-200 rounded-lg h-32 p-4 flex-0 lg:flex-1 w-full text-center">
                    <FaRegCalendarAlt className="text-3xl text-[#44add2] my-2" />
                    <p className="text-sm">من ٨ حتى ١٠</p>
                    <p className="text-sm"> سبتمبر ٢٠٢٤</p>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="my-4">
                  <div className="flex flex-col items-center justify-center bg-gray-200 rounded-lg h-32 p-4 flex-0 lg:flex-1 w-full text-center">
                    <MdOutlineMailOutline className="text-3xl text-[#44add2] my-2" />
                    <p className="text-sm">expo@sfma.sa</p>
                  </div>
                </div>
                <div className="my-4">
                  <div className="flex flex-col items-center justify-center bg-gray-200 rounded-lg h-32 p-4 flex-0 lg:flex-1 w-full text-center">
                    <MdLocationOn className="text-3xl text-[#44add2] my-2" />
                    <p className="text-sm">{t("Register.Address")}</p>
                  </div>
                </div>
                <div className="my-4 lg:hidden md:hidden sm:hidden block">
                  <div className="flex flex-col items-center justify-center bg-gray-200 rounded-lg h-32 p-4 flex-0 lg:flex-1 w-full text-center">
                    <FaRegCalendarAlt className="text-3xl text-[#44add2] my-2" />
                    <p className="text-sm">من ٨ حتى ١٠</p>
                    <p className="text-sm"> سبتمبر ٢٠٢٤</p>
                  </div>
                </div>
                <div className="my-4">
                  <div className="flex flex-col items-center justify-center bg-gray-200 rounded-lg h-32 p-4 flex-0 lg:flex-1 w-full text-center">
                    <BsClockFill className="text-3xl text-[#44add2] my-2" />
                    <p className="text-sm">المؤتمر : من. ٩ ص الى ٦ م</p>
                    <p className="text-sm">المعرض : من. ٩ ص الى ٧ م</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-80 px-4 lg:mb-6 md:mb-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.627061066577!2d46.718289299999995!3d24.8082207!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2efc5ababc7065%3A0xbd4b4b5828b1d31a!2sFairmont%20Riyadh!5e0!3m2!1sen!2seg!4v1725197454641!5m2!1sen!2seg"
                style={{
                  width: "100%",
                  height: "250px",
                  margin: "auto",
                }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div
            className="lg:col-span-7 md:col-span-7 col-span-12"
            id="ConferanceLogin"
          >
            <RegisterForm setIsModalOpen={setIsModalOpen} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConferanceLogin;
