import React, { useEffect, useState } from "react";
import aboutImage from "../../Images/aboutImage.png";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Badg from "../Badg/Badg";
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from "react-icons/io";
import "./GetYourId.css";

const GetYourId = () => {
  const { t, i18n } = useTranslation();
  const [badgeData, setBadgeData] = useState();
  const [query, setQuery] = useState("");
  const [searchId, setSearchId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isClicked, setISClicked] = useState(false);

  // This function will be called on every input change
  const handleChange = (event) => {
    const value = event.target.value;
    setQuery(value);
  };

  useEffect(() => {
    document.title = "GetYourId";
  }, []);

  const getBadge = async (searchTerm) => {
    setLoading(true);
    try {
      axios
        .get("https://admin.fmexcon.com/api/geBadgee", {
          params: {
            search: searchTerm,
          },
        })
        .then((res) => {
          if (res?.data?.data) {
            setLoading(false);
            setBadgeData(res?.data?.data);
            setSearchId(res?.data?.data?.id);
          } else {
            setTimeout(() => {
              setLoading(false);
              setBadgeData(res?.data?.data);
              setSearchId(res?.data?.data?.id);
            }, 400);
          }
        });
    } catch (err) {
      setLoading(false);
      console.error("Error fetching badge data:", err);
    }
  };

  return (
    <section
      className={`about ${i18n.language === "en" ? "text-left" : "text-right"}`}
      dir={i18n.language === "en" ? "ltr" : "rtl"}
      id="conditions"
    >
      <div className=" lg:col-span-12 col-span-12  back-ground">
        <img
          src={aboutImage}
          className="about-img col-span-12"
          alt="aboutImage"
        />
        <div className="text text-center">
          <p className="title lg:text-[64px] md:text-[64px] text-5xl">
            {t("BadgId.title")}
          </p>
          <p className="mt-6 text-white">powered by SFMA</p>
        </div>
      </div>

      <div className="col-span-12 px-3 mt-10 min-h-[70vh]">
        <div className="flex ">
          <div className="flex mx-auto lg:w-fit md:w-fit w-full">
            <input
              name="search"
              className={`text-right rounded-xl py-3 px-4 lg:w-[60vw] md:w-[60vw] w-full lg:text-[20px] md:text-[20px] text-sm `}
              style={{
                outline: "none",
                border: "1.8px solid #44add2",
                fontWeight: "semibold",
                textAlign: i18n.language === "en" ? "left" : "right",
              }}
              type="text"
              value={query}
              onChange={handleChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  getBadge(query);
                  setISClicked(true);
                }
              }}
              placeholder={t("BadgId.searchPlaceholder")}
            />
            <div
              className="rounded-lg bg-[#44add2] hover:bg-[#319cc4] flex items-center justify-center lg:px-10 md:px-10 px-5 py-2 mx-3 cursor-pointer"
              onClick={() => {
                getBadge(query);
                setISClicked(true);
              }}
            >
              <p className="text-white font-bold lg:text-lg md:text-lg text-sm">
                {t("BadgId.search")}
              </p>
            </div>
          </div>
        </div>
        {isClicked && (
          <div className="text-center mt-6">
            {loading ? (
              <div class="flex items-center justify-center mt-24">
                <div class="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-[#44add2]"></div>
              </div>
            ) : searchId ? (
              <div dir="ltr">
                <Badg searchId={searchId} />
              </div>
            ) : (
              <div className="text-center">
                <p className="text-[#dc3545] font-semibold text-2xl mt-24 mb-12">
                  {t("BadgId.p1")}
                </p>
                <Link
                  className="flex items-center gap-3 py-2 px-4 lg:w-fit md:w-fit w-fit rounded-md text-white font-bold text-sm bg-[#44add2] hover:bg-[#319cc4] mx-auto"
                  to={i18n.language === "en" ? "/en/register" : "/ar/register"}
                >
                  <span className="text-lg">{t("BadgId.goToRegister")}</span>
                  {i18n.language === "en" ? (
                    <IoMdArrowRoundForward className="text-lg" />
                  ) : (
                    <IoMdArrowRoundBack className="text-lg" />
                  )}
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default GetYourId;
