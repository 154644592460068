import React, { useEffect } from "react";
import "./About.css";
import aboutImage from "../../Images/aboutImage.png";
import aboutImage1 from "../../Images/about-image1.png";
import aboutImage2 from "../../Images/about-image2.png";
import aboutImage3 from "../../Images/about-image3.png";
import { useTranslation } from "react-i18next";
import RegisterWithUs from "../../Components/RegisterWithUs/RegisterWithUs";
import GetNewUpdates from "../../Components/GetNewUpdates/GetNewUpdates";

const About = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = "About";
  }, []);

  return (
    <section
      className="about grid grid-cols-12 gap-5 text-center"
      dir={i18n.language == "en" ? "ltr" : "rtl"}
    >
      <div className=" lg:col-span-12 col-span-12  back-ground">
        <img
          src={aboutImage}
          className="about-img col-span-12"
          alt="aboutImage"
        />
        <div className="text">
          <h3>{t("about.aboutUs")}</h3>
          <p className="mt-6 text-white">powerd by SFMA</p>
        </div>
      </div>
      <div className="col-span-12">
        <div className="lg:grid md:grid grid-cols-12 px-16 mt-16 mb-10">
          <div
            className={`lg:col-span-3 md:col-span-3 col-span-12 my-auto  ${
              i18n.language == "en" ? "text-left" : "text-right"
            }`}
          >
            <p
              style={{
                color: "#44add2",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              {t("about.p1")}
            </p>
            <p
              style={{
                color: "#103783",
                fontWeight: "bold",
                fontSize: "24px",
              }}
            >
              {t("about.p2")}
            </p>
          </div>
          <div className={`lg:col-span-9 md:col-span-9 col-span-12 `}>
            <p className={`text-sm leading-loose text-justify`}>
              {t("about.p3")}.
            </p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-x-8 col-span-12 px-16">
        <div className="card text-center p-4">
          <img
            src={aboutImage1}
            alt="Card 1"
            className="mx-auto mb-4 w-24 h-24 object-cover"
          />
          <h2
            className="text-lg font-semibold mb-2"
            style={{
              color: "rgb(68, 173, 210)",
            }}
          >
            {t("about.card1Title")}
          </h2>
          <p
            style={{
              color: "rgb(2, 48, 71)",
              textAlign: "justify",
            }}
          >
            {t("about.card1Content")}
          </p>
        </div>
        <div className="card text-center p-4">
          <img
            src={aboutImage2}
            alt="Card 2"
            className="mx-auto mb-4 w-24 h-24 object-cover"
          />
          <h2
            className="text-lg font-semibold mb-2"
            style={{
              color: "rgb(68, 173, 210)",
            }}
          >
            {t("about.card2Title")}
          </h2>
          <p
            style={{
              color: "rgb(2, 48, 71)",
              textAlign: "justify",
            }}
          >
            {t("about.card2Content")}
          </p>
        </div>
        <div className="card text-center p-4">
          <img
            src={aboutImage3}
            alt="Card 3"
            className="mx-auto mb-4 w-24 h-24 object-cover"
          />
          <h2
            className="text-lg font-semibold mb-2"
            style={{
              color: "rgb(68, 173, 210)",
            }}
          >
            {t("about.card3Title")}
          </h2>
          <p
            style={{
              color: "rgb(2, 48, 71)",
              textAlign: "justify",
            }}
          >
            {t("about.card3Content")}
          </p>
        </div>
      </div>
      <div className="col-span-12">
        <RegisterWithUs />
      </div>
      <div className="col-span-12 -mt-16">
        <GetNewUpdates />
      </div>
    </section>
  );
};

export default About;
