import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Sponsers from "./Pages/Sponsers/Sponsers";
import ConferanceLogin from "./Pages/ConferanceLogin/ConferanceLogin";
import Activites from "./Pages/Activites/Activites";
import Conferance from "./Pages/Conferance/Conferance";
import Conditions from "./Pages/Conditions/Conditions";
import ScrollToTop from "./helpers/ScrollTop";
import Badg from "./Pages/Badg/Badg";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import GetYourId from "./Pages/GetYourId/GetYourId";

function App() {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/en")) {
      i18n.changeLanguage("en");
      localStorage.setItem("I18N_LANGUAGE", "en");
    } else if (location.pathname.includes("/ar")) {
      i18n.changeLanguage("ar");
      localStorage.setItem("I18N_LANGUAGE", "ar");
    }
  }, []);

  return (
    <div className="App">
      <ScrollToTop />
      <ToastContainer />
      <Navbar />
      <Routes>
        <Route path="/en/home" element={<Home />} />
        <Route path="/ar/home" element={<Home />} />

        {/* Redirect from the root "/" to the appropriate language path */}
        <Route
          path="/"
          exact
          element={
            <Navigate to={i18n.language === "en" ? "/en/home" : "/ar/home"} />
          }
        />
        <Route
          path={i18n.language === "en" ? "/en/About" : "/ar/About"}
          element={<About />}
        />
        <Route
          path={i18n.language === "en" ? "/en/sponsor" : "/ar/sponsor"}
          element={<Sponsers />}
        />
        <Route
          path={i18n.language === "en" ? "/en/conferance" : "/ar/conferance"}
          element={<Conferance />}
        />
        <Route
          path={i18n.language === "en" ? "/en/register" : "/ar/register"}
          element={<ConferanceLogin />}
        />
        <Route
          path={i18n.language === "en" ? "/en/price" : "/ar/price"}
          element={<Activites />}
        />
        <Route
          path={i18n.language === "en" ? "/en/pdf" : "/ar/pdf"}
          element={<Conditions />}
        />
        <Route
          path={i18n.language === "en" ? "/en/id/:id" : "/ar/id/:id"}
          element={<Badg />}
        />
        <Route
          path={i18n.language === "en" ? "/en/get-badge" : "/ar/get-badge"}
          element={<GetYourId />}
        />
      </Routes>
      {!location.pathname.includes("/id") &&
        !location.pathname.includes("/get-badge") && <Footer />}
    </div>
  );
}

export default App;
