import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as Yup from "yup";

const GetNewUpdates = ({ fromSponsers }) => {
  const { t, i18n } = useTranslation();
  let validationSchema = Yup.object({
    email: Yup.string().email("").required(t("common.emailValidation")),
  });

  const handleSave = async (values) => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
      const formData = new FormData();

      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          formData.append(key, values[key]);
        }
      }
      axios
        .post("https://admin.fmexcon.com/api/store-userEmail", formData)
        .then((res) => {
          toast.success(res?.data?.message);
        })
        .catch((errors) => {
          toast.error(errors?.response?.data?.errors?.email[0]);
        });
    } catch (err) {
      console.error("Validation errors:", err.errors);
    }
  };

  return (
    <section className="px-4">
      <p
        style={{
          color: "#44add2",
          fontWeight: "bold",
        }}
        className="text-2xl mt-12 mb-8 text-center"
      >
        {t("home.sec6Title")}
      </p>
      <div
        style={{
          backgroundColor: fromSponsers ? "rgb(238, 238, 238)" : "#f6f5f5",
        }}
        className="rounded-2xl lg:py-4 py-2 lg:px-10 px-6 w-fit mx-auto mb-16 md:pb-2 pb-6"
      >
        <Formik
          initialValues={{ email: "" }}
          validationSchema={validationSchema}
          onSubmit={(values, formikBag) => {
            console.log(formikBag);
            formikBag.setErrors({});
          }}
        >
          {({
            handleSubmit,
            errors,
            touched,
            values,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form
              onSubmit={handleSubmit}
              className="flex justify-center items-center flex-wrap gap-x-4"
            >
              <div className="flex flex-col lg:w-96 md:w-72 w-full">
                <Field
                  type="email"
                  name="email"
                  placeholder={t("home.emialPlaceholder")}
                  className=" text-right rounded-xl border-none py-4 px-4 lg:mt-0 md:mt-0 mt-4"
                  value={values.email}
                  onChange={(e) => {
                    setFieldValue("email", e.target.value);
                  }}
                  required
                />
              </div>
              <button
                type="submit"
                onClick={() => {
                  handleSave(values);
                }}
                className="py-3 px-12 lg:w-fit md:w-fit w-full rounded-xl text-white font-bold lg:mt-0 md:mt-0 mt-4 bg-[#44add2] hover:bg-[#319cc4]"
              >
                {t("home.send")}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default GetNewUpdates;
