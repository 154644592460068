import React from "react";
import "./SponserMinister.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ministerImage from "../../Images/minister.png";

const SponserMinister = () => {
  const { t, i18n } = useTranslation();

  return (
    <section
      className="sponser-minister"
      dir={i18n.language == "en" ? "ltr" : "rtl"}
    >
      <div className="lg:grid md:grid grid-cols-12 ">
        <div className=" lg:col-span-6 md:col-span-6 col-span-12 p-12 relative minister-watermark">
          <img
            src={ministerImage}
            className=" mx-auto w-full max-w-[500px]"
            alt="minister_image"
          />
        </div>
        <div className=" lg:col-span-6 md:col-span-6 col-span-12 lg:p-16 md:p-12 px-6 lg:my-4 my-2 w-full h-full flex flex-col justify-center items-center lg:-mr-8">
          <p
            style={{
              color: "#44add2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            {t("home.p1")}
          </p>
          <p
            style={{
              color: "#103783",
              margin: "4px 0 12px 0",
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            {t("home.p2")}
          </p>
          <p
            style={{
              color: "rgb(53, 152, 219)",
              fontWeight: "bold",
            }}
            className="lg:text-3xl md:text-2xl text-2xl"
          >
            {t("home.p3")}
          </p>
          <p
            style={{
              margin: " 12px 0",
              fontSize: "20px",
            }}
          >
            {t("home.p4")}
          </p>
        </div>
      </div>
    </section>
  );
};

export default SponserMinister;
