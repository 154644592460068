import React from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
// import SwiperCore, { Keyboard, Mousewheel } from "swiper/core";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "./Carousel.css";
import { useTranslation } from "react-i18next";

const Carousel = ({ sponsers }) => {
  const swiper = useSwiper();
  const { t, i18n } = useTranslation();

  return (
    <section className="category container" dir={"rtl"}>
      <Swiper
        spaceBetween={50}
        keyboard={true}
        mousewheel={true}
        className="Swiper"
        modules={[Navigation, Autoplay]}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        loop={true}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        freeMode={true}
        freeModeMomentum={false}
        allowTouchMove={true}
        grabCursor={true}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        speed={2000}
      >
        <button
          onClick={() => swiper.slidePrev()}
          className="swiper-button-prev"
        ></button>
        {sponsers.map((slide, i) => (
          <SwiperSlide key={i}>
            <div className="category-img">
              <img
                src={slide?.imgSrc}
                className="swiper-img w-48 mx-auto"
                alt={`image ${slide?.name}`}
              />
            </div>
          </SwiperSlide>
        ))}
        <div className="swiper-button-next-container">
          <button
            onClick={() => swiper.slideNext()}
            className="swiper-button-next"
          ></button>
        </div>
      </Swiper>
    </section>
  );
};

export default Carousel;
