import React, { useEffect } from "react";
import WhatIsEvent from "../../Components/WhatIsEvent/WhatIsEvent";
import DigitalTransformation from "../../Components/DigitalTransformation/DigitalTransformation";
import CoOperation from "../../Components/CoOperation/CoOperation";
import WhyThisEvent from "../../Components/WhyThisEvent/WhyThisEvent";
import SponserMinister from "../../Components/SponserMinister/SponserMinister";
import Creativity from "../../Components/Creativity/Creativity";
import Sponser from "../../Components/Sponser/Sponser";
import "./Home.css";
import EventDetails from "../../Components/EventDetails/EventDetails";
import VideoSection from "../../Components/VideoSection/VideoSection";
import RegisterWithUs from "../../Components/RegisterWithUs/RegisterWithUs";
import GetNewUpdates from "../../Components/GetNewUpdates/GetNewUpdates";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import VisitSaudiCompany from "../../Components/VisitSaudiCompany/VisitSaudiCompany";

const Home = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = "Home";
  }, []);

  return (
    <>
      <head>
        <title>{t("common.main")}</title>
      </head>
      <section className="home">
        <section className="homeSec relative flex flex-col justify-center items-center w-full h-full">
          <div className="flex sm:flex-row justify-center items-center absolute bottom-8 left-0 right-0 mx-auto w-full px-4 max-w-2xl -translate-x-5">
            <button className="btn-view-home h-fit w-full lg:px-12 md:px-12 px-4 py-[9px] text-sm font-semibold">
              <HashLink
                smooth
                to={
                  i18n.language === "en"
                    ? "/en/sponsor#sponserForm"
                    : "/ar/sponsor#sponserForm"
                }
              >
                {t("common.exhibit")}
              </HashLink>
            </button>
            <button className="btn-register-home h-fit w-full lg:px-12 md:px-12  px-4 py-[9px] text-sm font-semibold">
              <HashLink
                smooth
                to={
                  i18n.language === "en"
                    ? "/en/register#ConferanceLogin"
                    : "/ar/register#ConferanceLogin"
                }
              >
                {t("common.register")}{" "}
              </HashLink>
            </button>
          </div>
        </section>
        <SponserMinister />
        <WhatIsEvent />
        <VisitSaudiCompany />
        <VideoSection />
        <EventDetails />
        <DigitalTransformation />
        <CoOperation />
        <Creativity />
        <WhyThisEvent />
        <Sponser />
        <RegisterWithUs />
        <GetNewUpdates />
      </section>
    </>
  );
};

export default Home;
