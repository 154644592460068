import React, { useEffect, useRef, useState } from "react";
import aboutImage from "../../Images/aboutImage.png";
import { useTranslation } from "react-i18next";
import GetNewUpdates from "../../Components/GetNewUpdates/GetNewUpdates";
import ConferanceForm from "../../Components/ConferanceForm/ConferanceForm";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
// import SwiperCore, { Keyboard, Mousewheel } from "swiper/core";
import { Navigation, Autoplay } from "swiper/modules";
import "../../Components/Carousel/Carousel.css";
import mazen from "../../Images/mazen.jpeg";
import elsrf from "../../Images/مهندس محمد الصرف.jpg";
import alzhrany from "../../Images/مهندس أحمد الزهراني.jpg";
import abdelhkim from "../../Images/عبدالحكيم عبدالعزيز البشر.jpg";
import anas from "../../Images/أنس بن عبدالله الزيد.jpg";
import abdelmohsen from "../../Images/أ. عبدالمحسن الشريم.jpg";
import sami from "../../Images/سامي أحمد العمري.jpg";
import saud from "../../Images/سعود بن محمد الشبانات.jpg";
import gary from "../../Images/Gary Malone.png";
import shukri from "../../Images/Shukri Habib.jpg";
import andreas from "../../Images/Andreas Hadjioannou.png";
import mona from "../../Images/باوزير.png";
import ebtsam from "../../Images/ابتسام.png";
import raad from "../../Images/Raad Nassar.png";
import mobarghash from "../../Images/Mo Barghash.png";
import rahul from "../../Images/Rahul Jain.png";
import firas from "../../Images/Firas Hassan.png";
import axios from "axios";
import { HashLink } from "react-router-hash-link";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "./Conferance.css";
import Schedule from "../../Components/Schedule/Schedule";

const Conferance = () => {
  const swiper = useSwiper();
  const { t, i18n } = useTranslation();
  const [conferanceData, setConferanceData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getConferanceData = async (values) => {
    setLoading(true);
    try {
      axios
        .get("https://admin.fmexcon.com/api/getAttends")
        .then((res) => {
          setLoading(false);
          setConferanceData(res?.data?.data);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    } catch (err) {
      console.error("Validation errors:", err.errors);
    }
  };

  useEffect(() => {
    getConferanceData();
  }, []);

  useEffect(() => {
    document.title = "Conferance";
  }, []);

  const speakers_ar = [
    {
      imgUrl: elsrf,
      title: "د. محمد بن سعيد الصرف",
      content:
        "أول سعودي حاصل على التالي:- عضو هيئة تدريس ب المجلس الأمريكي للأبنية الخضراء USGBC FACULTY - استشاري في الموارد الطبيعية وإعادة التدوير من امريكا TRUE ADVISOR - محترف في الاستدامة من الجمعية الدولية لمحترفي الاستدامة ISSP SA",
      leading: true,
    },
    {
      imgUrl: alzhrany,
      title: "مهندس أحمد الزهراني ",
      content:
        "المدير التنفيذي للتشغيل / ادارة المرافق ‎شركة تطوير المباني ‎ ‎خبرة لأكثر من ٢٥ سنة في مجال إدارة المرافق وإدارة سلسلة الإمداد ‎مهتم في تطوير الأداء للتشغيل والصيانة وتطبيق مفهوم إدارة المرافق",
      leading: true,
    },
    {
      imgUrl: abdelhkim,
      title: "عبدالحكيم عبدالعزيز البشر ",
      content:
        "الرئيس التنفيذي لشركة لوازم، المتخصصة في المشتريات التشغيلية. قاد إدارات المشتريات في عدة شركات حكومية، العمل كمستشار مبيعات في شركات عالمية.",
      leading: true,
    },
    {
      imgUrl: anas,
      title: "أنس بن عبدالله الزيد",
      content:
        "مهندس استشاري - عضو اللجنة الفنية لإعداد المعايير الوطنية للمنشآت الصحية - مدرب مقيمين معتمد من المجلس المركزي السعودي لاعتماد المنشآت الرعاية الصحية - المدير السابق لمنطقة الشرق الاوسط وشمال افريقيا الهيئة الوطنية الأمريكية للوقاية من الحرائق NFPA - مدير",
      leading: true,
    },
    {
      imgUrl: abdelmohsen,
      title: "أ . عبدالمحسن الشريم",
      content:
        "مصنف كمحترف في مجال السلامة من وزارة الموارد البشرية - نائب رئيس فريق المقيمين المعتمدين في مجال أمن وسلامة وهندسة المرافق الصحية في المركز السعودي لاعتماد المنشآت الصحية (سباهي) - ومقيم معتمد منذ عام ٢٠٠٨ - وأحد كتاب النسخة الثالثة من كتاب معايير المركز السعودي لاعتماد المستشفيات",
      leading: true,
    },
    {
      imgUrl: mona,
      title: "منى عبدالله بركات باوزير",
      content:
        "حاصلة على بكالوريوس لغـات ترجمـة (لغـة فرنسيـة) مـن جامعـة الملك سـعود - حاصلة على رخصـة مدربـة كـرة قـدم مـن الإتحـاد السعـودي لكـرة القـدم - عضـو في الجمعية السعودية للفنون التشكيلية (جسفت) - أعدت العديد من المبادرات المدرسية - خبير تطبيقات أهداف الأمم المتحدة للتنمية المستدامة 2030",
      leading: true,
    },
    {
      imgUrl: ebtsam,
      title: "ابتسام بنت فهد بن صالح السعيد",
      content:
        "عضو اللجنة السعودية لسوق العمل من 2022 - الآن - عضو الحماية الفكرية من 2018 - 2021 - مدير ريادة الأعمال لمعهد الأمير عبدالرحمن بن ناصر للاستشارات التابع لجامعة الأمير سطام بن عبدالعزيز عام 2016 م - سفيرة التوفير والادخار لحملة التوفير والادخار التابعة لإمارة الرياض",
      leading: true,
    },
    {
      imgUrl: sami,
      title: "سامي أحمد العمري",
      content:
        "بكالوريوس نظم معلومات جغرافية - الرئيس التنفيذي في شركة الاستشعار للاستشارات الجيومكانية - شريك مؤسس في شركة مشاع لإدارة المرافق",
      leading: true,
    },
    {
      imgUrl: saud,
      title: "م.سعود بن محمد الشبانات",
      content:
        "عضو الهيئة العربية السعودية للمواصفات والمقاييس منذ 2000م وحتى الآن - مدرب رئيسي في دبلوم الجودة الغرفة التجارية بالرياض - مستشار جودة: مركز دعم المنشآت – هيئة منشآت - عضو اللجنة العالمية للمدربين (BITA) - مدرب متعاون على نظم الجودة مع بعض مراكز التدريب - عضو اللجنة العلمية في الجمعية السعودية للجودة",
      leading: true,
    },
    {
      imgUrl: mazen,
      title: "مهندس مازن بن سعيد الغامدي",
      content:
        "ما يزيد عن 25 سنة خبرة في مجال إنشاء وصيانة وتشغيل المنشآت - عمل في العديد من شركات القطاع الخاص والشبه الحكومي ويعمل حاليا كمدير تنفيذي لإدارة المرافق بشركة المياه الوطنية",
      leading: true,
    },
    {
      imgUrl: gary,
      title: "Gary Malone",
      content:
        "Managing Director Rekeep Saudi Arabia - Irish national resident in Riyadh, with 25 years’ experience in FM and Asset management - 11 years in the Middle East - Holder of Engineering, Project Management qualifications and a Masters in Business Administration from University of Cumbria UK - Former Director of FM in Dubai Airports, Engineering Manager in UAE Ports, and Managing Director of Italian Integrated FM company Rekeep",
    },
    {
      imgUrl: shukri,
      title: "Dr: Shukri Habib",
      content:
        "Winner of IFMA Distinguished Educator Award for 2023 - EuroFM Ambassador to Lebanon 2023 – 2025 - A speaker at international and regional FM conferences such as IFMA, EuroFM - Member of the FM organizing committee for IFMA Global Middle East Summit - Facility management expert for several international organizations - Managing Director of QualiServ, one of the leading facility management consultancy and training companies in the Middle East",
    },
    {
      imgUrl: andreas,
      title: "Andreas Hadjioannou",
      content:
        "Andreas is a member of the technical committees of the ISO 55000 Asset Management and EN 15221 / ISO 41001 Facilities Management international standards - He is also a member of the subcommittees of these standards related to Asset and FM Digital Technologies",
      leading: true,
    },
    {
      imgUrl: raad,
      title: "Raad Nassar",
      content:
        "Raad is a Director within PwC’s Asset Lifecycle Management and Valuation (ALMV) Consulting Services practice. He has 19 years of work experience in the region combining consulting with industry experience. Raad is a well-rounded professional with extensive work experience in manufacturing and service, private and public top-notch organisations in the MENA region.",
      leading: true,
    },
    {
      imgUrl: mobarghash,
      title: "Mo Barghash",
      content:
        "Mo is a director at PwC Middle East’s Asset Lifecycle Management and Valuations Services, advising a diverse client base, including their C-Suite and Board of Directors. Mo gained experience working in different sectors, including power and utilities, rail, transportation, FMCG and manufacturing. Mo has broad experience across public and private sectors working in Australia and the Middle East for assessing client’s opportunities and maturities to develop plans to assist organisations with improving their efficiency and better management of their assets. Mo has an MBA from Melbourne Business School and a Bachelor of Industrial Engineering.",
    },
    {
      imgUrl: firas,
      title: "Firas Hassan",
      content:
        "Firas is a partner in the Consulting line of service at PricewaterhouseCoopers Middle East. He has more than 18 years of experience in Real Estate Advisory services where he led and executed many transformation projects in Saudi Arabia. Moreover, he led the strategy development and implementation for many mega real estate projects as part of Vision 2030. Prior to joining PricewaterhouseCoopers, Firas was the head of Real Estate Advisory at a leading consulting firm in Saudi and prior to that he was the Chief Executive Officer of Bait Al Mal AlKhaleeji (BMK) and a board member and member of the executive committee at Jubail Development Company.",
    },
    {
      imgUrl: rahul,
      title: "Rahul Jain",
      content:
        "Rahul is a Director in the Technology Consulting Practice based in Riyadh, with over 16 years of ICT consulting and implementation expertise. He has led numerous engagements in digital strategy, IT services, architecture, digital platform reviews, and implementation planning, consistently guiding clients through successful digital transformation initiatives. Rahul's extensive experience spans both public and private sector clients across the Middle East, India, and the Caribbean. Notably, he has worked with real estate clients and GIGA projects, leading technology-driven facilities and asset management initiatives and providing advanced technology solutions.",
    },
  ];

  const speakers_en = [
    {
      imgUrl: elsrf,
      title: "Dr: Mohammed Bn Said Al Saraf",
      content:
        "The first Saudi to achieve the following USGBC FACULTY: Faculty member of the US Green Building Council TRUE ADVISOR: Natural resources and recycling consultant from the United States ISSP SA: Sustainability Professional from the International Society of Sustainability Professionals",
      leading: true,
    },
    {
      imgUrl: alzhrany,
      title: "Eng: Ahmed Alzahrani",
      content:
        "Operations Manager / Facilities Management Building Development Company Over 25 years of experience in Facilities Management and Supply Chain Management Dedicated to performance improvement in operations and maintenance, and to implementing the Facilities Management concept.",
      leading: true,
    },
    {
      imgUrl: abdelhkim,
      title: "Abdelhakeem Abdelazeez Elbeshr",
      content:
        " CEO of a Supplies Company specializing in Operational Procurement. Led procurement departments in multiple government organizations. Served as a Sales Consultant in global corporations.",
      leading: true,
    },
    {
      imgUrl: anas,
      title: "ENG: Anas Bn Abdullah Alzaid",
      content:
        "Consulting Engineer - Played a pivotal role in developing national health facility standards as a member of the Technical Committee - Certified Assessor Trainer with the Saudi Central Council for Health Facilities Accreditation - Led the Middle East and North Africa region for the renowned National Fire Protection Association 'NFPA' - Spearheaded safety and security initiatives as the Director General at the Ministry of Health - Accredited Arbitrator by the Ministry of Justice - Active member of the Safety Committee at the Riyadh Chamber",
    },

    {
      imgUrl: abdelmohsen,
      title: "Mr: Abdul Mohsen Al Shreem",
      content:
        "Recognized as a Certified Safety Professional by the Ministry of Human Resources - Served as Vice President of the Board of Certified Assessors for Health Facility Safety, Security, and Engineering at SPAHA - Certified Assessor since 2008 with extensive experience in healthcare facility assessments - Contributed significantly to the third edition of the SPAHA Hospital Standards",
    },
    {
      imgUrl: mona,
      title: "Mona Abdallah Barakat Bawazeer",
      content:
        "Bachelor of Arts in Languages and Translation (French) from King Saud University - Holds a Football Coaching License from the Saudi Arabian Football Federation - Member of the Saudi Society for Fine Arts (JAST) - Has initiated numerous school-based projects - Experience in applying the UN 2030 Sustainable Development Goals",
    },
    {
      imgUrl: ebtsam,
      title: "Ebtsam Bnt Fahd Bn Saleh Elsaid",
      content:
        "Member of the Saudi Labor Market Committee since 2022 - Member of the Intellectual Property Committee from 2018 to 2021 - Director of Entrepreneurship at Prince Abdulaziz bin Nasser Institute for Consultations, affiliated with Prince Sattam bin Abdulaziz University in 2016 - Savings and Investment Ambassador for the Riyadh Governorate Savings and Investment Campaign",
    },
    {
      imgUrl: sami,
      title: "Samy Ahmed AlAmery",
      content:
        "Bachelor of Geographic Information Systems - CEO of Al-Ish'aar Geospatial Consulting Company - Co-founder of Mshaa Facilities Management Company",
      leading: true,
    },
    {
      imgUrl: saud,
      title: "Eng: Saud Bn Muhammed Al Shabanat",
      content:
        "Member of the Saudi Standards, Metrology and Quality Organization since 2000 - Lead Trainer for the Chamber of Commerce in Riyadh Quality Diploma - Quality Consultant at the Facilities Support Center - Monsha'at - Member of the International Board of Trainers (BITA) - Collaborative Trainer on Quality Systems with various training centers - Member of the Scientific Committee of the Saudi Quality Society",
    },
    {
      imgUrl: mazen,
      title: "Engineer Mazen bin Saeed Al-Ghamdi",
      content:
        "More than 25 years of experience in the field of construction, maintenance, and operation of facilities - He worked in many private and semi-governmental sector companies and currently works as Executive Director of Facilities Management at the National Water Company",
      leading: true,
    },
    {
      imgUrl: gary,
      title: "Gary Malone",
      content:
        "Managing Director Rekeep Saudi Arabia - Irish national resident in Riyadh, with 25 years’ experience in FM and Asset management - 11 years in the Middle East - Holder of Engineering, Project Management qualifications and a Masters in Business Administration from University of Cumbria UK - Former Director of FM in Dubai Airports, Engineering Manager in UAE Ports, and Managing Director of Italian Integrated FM company Rekeep",
    },
    {
      imgUrl: shukri,
      title: "Dr: Shukri Habib",
      content:
        "Winner of IFMA Distinguished Educator Award for 2023 - EuroFM Ambassador to Lebanon 2023 – 2025 - A speaker at international and regional FM conferences such as IFMA, EuroFM - Member of the FM organizing committee for IFMA Global Middle East Summit - Facility management expert for several international organizations - Managing Director of QualiServ, one of the leading facility management consultancy and training companies in the Middle East",
    },
    {
      imgUrl: andreas,
      title: "Andreas Hadjioannou",
      content:
        "Andreas is a member of the technical committees of the ISO 55000 Asset Management and EN 15221 / ISO 41001 Facilities Management international standards - He is also a member of the subcommittees of these standards related to Asset and FM Digital Technologies",
      leading: true,
    },
    {
      imgUrl: raad,
      title: "Raad Nassar",
      content:
        "Raad is a Director within PwC’s Asset Lifecycle Management and Valuation (ALMV) Consulting Services practice. He has 19 years of work experience in the region combining consulting with industry experience. Raad is a well-rounded professional with extensive work experience in manufacturing and service, private and public top-notch organisations in the MENA region.",
    },
    {
      imgUrl: mobarghash,
      title: "Mo Barghash",
      content:
        "Mo is a director at PwC Middle East’s Asset Lifecycle Management and Valuations Services, advising a diverse client base, including their C-Suite and Board of Directors. Mo gained experience working in different sectors, including power and utilities, rail, transportation, FMCG and manufacturing. Mo has broad experience across public and private sectors working in Australia and the Middle East for assessing client’s opportunities and maturities to develop plans to assist organisations with improving their efficiency and better management of their assets. Mo has an MBA from Melbourne Business School and a Bachelor of Industrial Engineering.",
    },
    {
      imgUrl: firas,
      title: "Firas Hassan",
      content:
        "Firas is a partner in the Consulting line of service at PricewaterhouseCoopers Middle East. He has more than 18 years of experience in Real Estate Advisory services where he led and executed many transformation projects in Saudi Arabia. Moreover, he led the strategy development and implementation for many mega real estate projects as part of Vision 2030. Prior to joining PricewaterhouseCoopers, Firas was the head of Real Estate Advisory at a leading consulting firm in Saudi and prior to that he was the Chief Executive Officer of Bait Al Mal AlKhaleeji (BMK) and a board member and member of the executive committee at Jubail Development Company.",
    },
    {
      imgUrl: rahul,
      title: "Rahul Jain",
      content:
        "Rahul is a Director in the Technology Consulting Practice based in Riyadh, with over 16 years of ICT consulting and implementation expertise. He has led numerous engagements in digital strategy, IT services, architecture, digital platform reviews, and implementation planning, consistently guiding clients through successful digital transformation initiatives. Rahul's extensive experience spans both public and private sector clients across the Middle East, India, and the Caribbean. Notably, he has worked with real estate clients and GIGA projects, leading technology-driven facilities and asset management initiatives and providing advanced technology solutions.",
    },
  ];

  return (
    <section
      className={`about grid grid-cols-12 gap-5  ${
        i18n.language == "en" ? "text-left" : "text-right"
      }`}
      dir={i18n.language == "en" ? "ltr" : "rtl"}
    >
      <div className=" lg:col-span-12 col-span-12  back-ground">
        <img
          src={aboutImage}
          className="about-img col-span-12"
          alt="aboutImage"
        />
        <div className="text text-center">
          <h3>{t("conference.conference")}</h3>
          <p className="mt-6 text-white">powerd by SFMA</p>
        </div>
      </div>
      <div className="col-span-12">
        <div className="lg:grid md:grid grid-cols-12 lg:px-16 md:px-16 px-4 mt-4 mb-10">
          <div className={`col-span-12 my-auto `}>
            <div className="flex justify-center gap-6 mt-4 ">
              <button
                type="submit"
                onClick={() => {
                  // handleSave(values);
                }}
                className="py-2 px-12 lg:w-fit md:w-fit w-full rounded-md text-white font-bold text-sm bg-[#44add2] hover:bg-[#319cc4]"
              >
                <HashLink
                  to={
                    i18n.language == "en"
                      ? "/en/register#ConferanceLogin"
                      : "/ar/register#ConferanceLogin"
                  }
                >
                  {t("conference.b1")}
                </HashLink>
              </button>
              <button
                type="submit"
                onClick={() => {
                  // handleSave(values);
                }}
                className="py-2 px-12 lg:w-fit md:w-fit w-full rounded-md text-white font-bold text-sm bg-[#44add2] hover:bg-[#319cc4]"
              >
                <a href="#login-with-us">{t("conference.b2")}</a>
              </button>
            </div>
            <div className="flex justify-center gap-6 mt-4 mb-12">
              <button
                type="submit"
                onClick={() => {
                  // handleSave(values);
                }}
                className="py-2 px-12 lg:w-fit md:w-fit w-full rounded-md text-white font-bold text-sm bg-[#44add2] hover:bg-[#319cc4]"
              >
                <a href="#ConferenceProgram">{t("conference.b3")}</a>
              </button>
            </div>
            <p className="text-[#103783] font-bold text-3xl text-center">
              {t("conference.h1")}
            </p>
            <p className="text-lg px-4 mt-4 text-[#3598db] text-center font-bold">
              {t("conference.h2")}
            </p>{" "}
            <p className="text-lg px-4 text-[#3598db] text-center font-bold">
              {t("conference.h3")}
            </p>
          </div>
        </div>
      </div>
      <div className="col-span-12 px-3">
        <div className="lg:grid md:grid grid-cols-12 lg:px-16 md:px-16 px-4 mb-10">
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#023047] font-bold text-sm mb-1">
              {t("conference.p1Title")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p1l1")}
            </p>
          </div>
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#023047] font-bold text-sm mb-1 mt-6">
              {t("conference.p2Title")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p2l1")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p2l2")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p2l3")}
            </p>
          </div>
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#023047] font-bold text-sm mb-1 mt-6">
              {t("conference.p3Title")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p3l1")}
            </p>
          </div>
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#023047] font-bold text-sm mb-1 mt-6">
              {t("conference.p4Title")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p4l1")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p4l2")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p4l3")}
            </p>
          </div>
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#023047] font-bold text-sm mb-1 mt-6">
              {t("conference.p5Title")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p5l1")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p5l2")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p5l3")}
            </p>
          </div>
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#023047] font-bold text-sm mb-1 mt-6">
              {t("conference.p6l1")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p6l1")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p6l2")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p6l3")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p6l4")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p6l5")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p6l6")}
            </p>
          </div>
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#023047] font-bold text-sm mb-1 mt-6">
              {t("conference.p7l2")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p7l3")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p7l4")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p7l5")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p7l6")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p7l7")}
            </p>
          </div>
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#023047] font-bold text-sm mb-1 mt-6">
              {t("conference.p7Title")}
            </p>
            <p className="text-sm text-[#023047]">{t("conference.p7l1")}</p>
          </div>
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#023047] font-bold text-sm mb-1 mt-6">
              {t("conference.p8Title")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p8l1")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p8l2")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p8l3")}
            </p>
            <p className="text-sm text-[#023047] text-justify">
              {t("conference.p8l4")}
            </p>
          </div>
        </div>
      </div>

      <div className="col-span-12" id="ConferenceProgram">
        <Schedule />
      </div>

      <div className={`col-span-12 my-auto lg:px-16 md:px-16 px-4 `}>
        <p
          className="text-sm text-[#44add2] text-center font-bold mt-4 lg:px-24 md:px-24 sm:px-12"
          id="login-with-us"
        >
          {t("conference.quate")}
        </p>
      </div>

      <div className="col-span-12">
        <ConferanceForm />
      </div>

      <div className="col-span-12">
        <section className="mt-8" dir={i18n.language == "en" ? "ltr" : "rtl"}>
          <p className="text-3xl px-4 text-[#44add2] text-center font-bold -mt-4 mb-4">
            {t("conference.bottomSecHead")}
          </p>
          <div
            className={`why-this-event lg:px-16 md:px-16 px-4  ${
              i18n.language == "en" ? "text-left" : "text-right"
            }`}
            style={{
              padding: "60px 0 50px 0",
              margin: 0,
              minHeight: "500px",
              height: "700px",
            }}
          >
            <div className="conferance container relative" dir={"rtl"}>
              <Swiper
                spaceBetween={50}
                keyboard={true}
                mousewheel={true}
                className="Swiper"
                modules={[Navigation, Autoplay]}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                loop={true}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 1,
                  },
                  1024: {
                    slidesPerView: 1,
                  },
                }}
                freeMode={true}
                freeModeMomentum={false}
                allowTouchMove={true}
                grabCursor={true}
                autoplay={{
                  delay: 0,
                  disableOnInteraction: false,
                  reverseDirection: true,
                }}
                speed={2300}
              >
                <button
                  onClick={() => swiper.slidePrev()}
                  className="swiper-button-prev"
                ></button>
                {loading ? (
                  <div className="lg:grid md:grid grid-cols-12 gap-6 mx-auto w-fit h-full">
                    <div className="bg-white shadow-lg rounded-lg p-6 my-8 box col-span-12 w-80 animate-pulse">
                      <div className="my-2 text-center">
                        <div className="bg-gray-300 h-24 w-24 mx-auto rounded-full mb-2 -translate-y-20"></div>
                      </div>
                      <div className="relative -translate-y-10">
                        <div className="bg-gray-300 rounded-lg h-40 w-full"></div>
                      </div>
                    </div>
                  </div>
                ) : i18n.language == "en" ? (
                  conferanceData?.map((slide, i) => (
                    <SwiperSlide key={i}>
                      <div
                        className="lg:grid md:grid grid-cols-12 gap-6 mx-auto w-fit  h-full cursor-grab"
                        style={{ minHeight: "500px" }}
                      >
                        <div className="bg-white shadow-lg rounded-lg py-6 lg:px-1  my-8 box col-span-12 lg:w-96 w-80">
                          <div className="relative">
                            <img
                              className=" speaker-img"
                              src={`https://admin.fmexcon.com/public/images/${slide?.image}`}
                              alt="SpeakerImage"
                            />
                          </div>
                          <div className="my-2 text-center">
                            <h3 className="text-xl font-bold mb-2 text-[#44add2]">
                              {slide?.name_en}
                            </h3>
                            <p
                              className="text-gray-800 px-2 font-semibold"
                              style={{ lineHeight: slide.leading ? "2.5" : "" }}
                            >
                              {slide?.description_en}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))
                ) : (
                  conferanceData?.map((slide, i) => (
                    <SwiperSlide key={i}>
                      <div
                        className="lg:grid md:grid grid-cols-12 gap-6 mx-auto w-fit  h-full cursor-grab"
                        style={{ minHeight: "500px" }}
                      >
                        <div className="bg-white shadow-lg rounded-lg py-6 lg:px-1  my-8 box col-span-12 lg:w-96 w-80 ">
                          <div className="relative">
                            <img
                              className=" speaker-img"
                              src={`https://admin.fmexcon.com/public/images/${slide?.image}`}
                              alt="SpeakerImage"
                            />
                          </div>
                          <div className="my-2 text-center">
                            <h3 className="text-xl font-bold mb-2 text-[#44add2]">
                              {slide?.name_ar}
                            </h3>
                            <p
                              className={`text-gray-800 px-2 font-semibold `}
                              style={{ lineHeight: slide.leading ? "2.5" : "" }}
                            >
                              {slide?.description_ar}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))
                )}

                <div className="swiper-button-next-container">
                  <button
                    onClick={() => swiper.slideNext()}
                    className="swiper-button-next"
                  ></button>
                </div>
              </Swiper>
            </div>
          </div>
        </section>
      </div>

      <div className="col-span-12 ">
        <GetNewUpdates />
      </div>
    </section>
  );
};

export default Conferance;
