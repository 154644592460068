import React, { useEffect } from "react";
import "./Conditions.css";
import aboutImage from "../../Images/aboutImage.png";
import { useTranslation } from "react-i18next";

const Conditions = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = "Conditions";
  }, []);

  return (
    <section
      className={`about grid grid-cols-12 gap-5  ${
        i18n.language == "en" ? "text-left" : "text-right"
      }`}
      dir={i18n.language == "en" ? "ltr" : "rtl"}
      id="conditions"
    >
      <div className=" lg:col-span-12 col-span-12  back-ground">
        <img
          src={aboutImage}
          className="about-img col-span-12"
          alt="aboutImage"
        />
        <div className="text text-center">
          <h3>{t("conditions.conditions")}</h3>
          <p className="mt-6 text-white">powerd by SFMA</p>
        </div>
      </div>

      <div className="col-span-12 px-3">
        <div className="lg:grid md:grid grid-cols-12 px-16 mb-10">
          <div className={`col-span-12 my-auto font-semibold`}>
            <p className="text-[#212529] font-bold text-3xl mb-8 mt-8">
              {t("conditions.p1")}
            </p>
          </div>
          <div className={`col-span-12 my-auto font-semibold`}>
            <p className="text-[#3598db] font-bold text-sm mb-1">
              {t("conditions.p2")}
            </p>
            <p className="text-sm text-[#023047] my-2 leading-loose text-justify">
              {t("conditions.p3")}
            </p>
          </div>
          <div className={`col-span-12 my-auto font-semibold`}>
            <p className="text-[#3598db] font-bold text-sm mb-1 mt-6">
              {t("conditions.p4")}
            </p>
            <p className="text-sm text-[#023047] my-2">
              {t("conditions.p4l1")}
            </p>
            <p className="text-sm text-[#023047]">{t("conditions.p4l2")}</p>
          </div>
          <div className={`col-span-12 my-auto font-semibold`}>
            <p className="text-[#3598db] font-bold text-sm mb-1 mt-6 my-2">
              {t("conditions.p6")}
            </p>
            <p className="text-sm text-[#023047] my-2">
              {t("conditions.p6l1")}
            </p>
            <p className="text-sm text-[#023047] my-2">
              {t("conditions.p6l2")}
            </p>
            <p className="text-sm text-[#023047]">{t("conditions.p6l3")}</p>
          </div>
          <div className={`col-span-12 my-auto font-semibold`}>
            <p className="text-[#3598db] font-bold text-sm mb-1 mt-6 my-2">
              {t("conditions.p7")}
            </p>
            <p className="text-sm text-[#023047] leading-loose">
              {t("conditions.p7l1")}
            </p>
          </div>
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#e03e2d] font-bold lg:text-xl md:text-xl mb-1 mt-6 text-center my-2">
              {t("conditions.p8")}
            </p>
            <p className="text-sm text-[#023047] font-bold leading-loose text-justify">
              {t("conditions.p9")}
            </p>
          </div>
          <div className={`col-span-12 my-auto font-semibold`}>
            <p className="text-[#3598db] font-bold text-sm mb-1 mt-12">
              {t("conditions.p10")}
            </p>
            <div className="flex lg:justify-start md:justify-start sm:justify-start justify-center gap-6 mt-4">
              <button
                type="submit"
                onClick={() => {
                  // handleSave(values);
                }}
                style={{ backgroundColor: "#44add2" }}
                className="py-2 px-6 lg:w-fit md:w-fit w-full rounded-md text-white font-bold text-sm"
              >
                <a
                  href={
                    i18n.language == "en"
                      ? "/files/Call for presentation - International Facility Management Conference and Exhibition 2024.pdf"
                      : "/files/Arabic Call for presentation - International Facility Management Conference and Exhibition 2024.pdf"
                  }
                  download
                >
                  {t("conditions.button1")}
                </a>
              </button>
              <button
                type="submit"
                onClick={() => {
                  // handleSave(values);
                }}
                style={{ backgroundColor: "#44add2" }}
                className="py-2 px-6 lg:w-fit md:w-fit w-full rounded-md text-white font-bold text-sm"
              >
                <a href="/files/PDF 2 Ar.pdf" download>
                  {t("conditions.button2")}
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Conditions;
