import React, { createContext, useState } from "react";

export const GeneralContext = createContext();

export const GeneralContextProvider = ({ children }) => {
  const [badgeData, setBadgeData] = useState({});

  return (
    <GeneralContext.Provider value={{ badgeData, setBadgeData }}>
      {children}
    </GeneralContext.Provider>
  );
};
