import React, { useContext, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import axios from "axios";
import country_list from "../../helpers/countryList";
import { useTranslation } from "react-i18next";
import fields_list_en from "../../helpers/fieldsList_en";
import fields_list_ar from "../../helpers/fieldsList_ar";
import { GeneralContext } from "../../Context/GeneralContext";
import { useNavigate } from "react-router-dom";

const RegisterForm = ({ setIsModalOpen }) => {
  const { t, i18n } = useTranslation();
  const { setBadgeData } = useContext(GeneralContext);
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    name: Yup.string().required(t("common.nameValidation")),
    email: Yup.string().email("").required(t("common.emailValidation")),
    phone: Yup.string()
      .matches(/^05\d{8}$/, t("common.phoneValidation1"))
      .required(t("common.phoneValidation2")),
    job_title: Yup.string().required(t("common.companyValidation")),
    position: Yup.string().required(t("common.positionValidation")),
    city: Yup.string().required(t("common.cityValidation")),
    country: Yup.string().required(t("common.countryValidation")),
    sector: Yup.string().required(t("common.sectorValidation")),
    sex: Yup.string().required(t("common.genderValidation")),
    checkbox: Yup.string().required(t("common.genderValidation")),
  });

  const gender_ar = [
    { value: "ذكر", label: "ذكر" },
    { value: "أنثى", label: "أنثى" },
  ];
  const gender_en = [
    { value: "male", label: "male" },
    { value: "female", label: "female" },
  ];

  const handleSave = async (values) => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
      const formData = new FormData();

      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          formData.append(key, values[key]);
        }
      }
      axios
        .post("https://admin.fmexcon.com/api/userRegisterStore", formData)
        .then((res) => {
          console.log(res?.data?.data[0]?.id);
          navigate(
            `${
              i18n.language === "en"
                ? `/en/id/${res?.data?.data?.id}`
                : `/ar/id/${res?.data?.data?.id}`
            }`
          );
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error("Validation errors:", err.errors);
    }
  };

  return (
    // <Formik
    //   initialValues={{
    //     name: "",
    //     email: "",
    //     position: "",
    //     job_title: "",
    //     phone: "",
    //     sector: "",
    //     sex: "",
    //     country: "",
    //     city: "",
    //   }}
    //   validationSchema={validationSchema}
    //   onSubmit={(values, formikBag) => {
    //     formikBag.setErrors({});
    //   }}
    // >
    //   {({
    //     handleSubmit,
    //     errors,
    //     touched,
    //     values,
    //     isSubmitting,
    //     setFieldValue,
    //   }) => (
    //     <Form onSubmit={handleSubmit} className="w-full max-w-2xl mx-auto p-4">
    //       <div className="flex flex-col mb-2">
    //         <label>
    //           {t("Register.Name")}{" "}
    //           <span className="text-red-700 text-xl">*</span>
    //         </label>
    //         <Field
    //           name="name"
    //           className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
    //           value={values.name}
    //           onChange={(e) => {
    //             setFieldValue("name", e.target.value);
    //           }}
    //         />
    //         <ErrorMessage
    //           name="name"
    //           component="div"
    //           className="text-red-500 text-sm"
    //         />
    //       </div>
    //       <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-2">
    //         <div className="flex flex-col">
    //           <label>
    //             {t("Register.emailAddress")}{" "}
    //             <span className="text-red-700 text-xl">*</span>
    //           </label>
    //           <Field
    //             name="email"
    //             type="email"
    //             className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
    //             value={values.email}
    //             onChange={(e) => {
    //               setFieldValue("email", e.target.value);
    //             }}
    //           />
    //           <ErrorMessage
    //             name="email"
    //             component="div"
    //             className="text-red-500 text-sm"
    //           />
    //         </div>
    //         <div className="flex flex-col">
    //           <label>
    //             {t("Register.phoneNumber")}{" "}
    //             <span className="text-red-700 text-xl">*</span>
    //           </label>
    //           <Field
    //             name="phone"
    //             className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
    //             value={values.phone}
    //             onChange={(e) => {
    //               setFieldValue("phone", e.target.value);
    //             }}
    //           />
    //           <ErrorMessage
    //             name="phone"
    //             component="div"
    //             className="text-red-500 text-sm"
    //           />
    //         </div>
    //       </div>
    //       <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-2">
    //         <div className="flex flex-col">
    //           <label htmlFor="position">
    //             {t("Register.Position")}{" "}
    //             <span className="text-red-700 text-xl">*</span>
    //           </label>
    //           <Field
    //             name="position"
    //             className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
    //             value={values.position}
    //             onChange={(e) => {
    //               setFieldValue("position", e.target.value);
    //             }}
    //           />
    //           <ErrorMessage
    //             name="position"
    //             component="div"
    //             className="text-red-500 text-sm"
    //           />
    //         </div>
    //         <div className="flex flex-col">
    //           <label>
    //             {t("Register.company")}{" "}
    //             <span className="text-red-700 text-xl">*</span>
    //           </label>
    //           <Field
    //             name="job_title"
    //             className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
    //             value={values.job_title}
    //             onChange={(e) => {
    //               setFieldValue("job_title", e.target.value);
    //             }}
    //           />
    //           <ErrorMessage
    //             name="job_title"
    //             component="div"
    //             className="text-red-500 text-sm"
    //           />
    //         </div>
    //       </div>
    //       <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
    //         <div className="flex flex-col">
    //           <label htmlFor="sector">
    //             {t("Register.Sector")}{" "}
    //             <span className="text-red-700 text-xl">*</span>
    //           </label>
    //           <Select
    //             options={
    //               i18n.language == "en" ? fields_list_en : fields_list_ar
    //             }
    //             className="mt-4"
    //             placeholder={t("Register.SectorPlaceholder")}
    //             name="sector"
    //             value={
    //               i18n.language == "en"
    //                 ? fields_list_en?.find(
    //                     (option) => option?.value == values?.sector
    //                   )
    //                 : fields_list_ar?.find(
    //                     (option) => option?.value == values?.sector
    //                   )
    //             }
    //             onChange={(option) => {
    //               setFieldValue("sector", option?.value);
    //             }}
    //             isClearable
    //           />
    //           <ErrorMessage
    //             name="sector"
    //             component="div"
    //             className="text-red-500 text-sm"
    //           />
    //         </div>
    //         <div className="flex flex-col">
    //           <label htmlFor="sex">
    //             {t("Register.Gender")}{" "}
    //             <span className="text-red-700 text-xl">*</span>
    //           </label>
    //           <Select
    //             options={i18n.language == "en" ? gender_en : gender_ar}
    //             className="mt-4"
    //             placeholder={t("Register.GenderPlaceholder")}
    //             type="select"
    //             name="sex"
    //             value={
    //               i18n.language == "en"
    //                 ? gender_en?.find((option) => option?.value == values?.sex)
    //                 : gender_ar?.find((option) => option?.value == values?.sex)
    //             }
    //             onChange={(option) => {
    //               setFieldValue("sex", option?.value);
    //             }}
    //             isClearable
    //           />
    //           <ErrorMessage
    //             name="sex"
    //             component="div"
    //             className="text-red-500 text-sm"
    //           />
    //         </div>
    //       </div>
    //       <div className="flex flex-col mb-4">
    //         <label htmlFor="country">
    //           {t("Register.Country")}{" "}
    //           <span className="text-red-700 text-xl">*</span>
    //         </label>
    //         <Select
    //           options={country_list}
    //           className="mt-3"
    //           placeholder={t("Register.CountryPlaceholder")}
    //           name="country"
    //           value={
    //             i18n.language == "en"
    //               ? country_list?.find(
    //                   (option) => option?.value == values?.country
    //                 )
    //               : country_list?.find(
    //                   (option) => option?.value == values?.country
    //                 )
    //           }
    //           onChange={(option) => {
    //             setFieldValue("country", option?.value);
    //           }}
    //           isClearable
    //         />
    //         <ErrorMessage
    //           name="country"
    //           component="div"
    //           className="text-red-500 text-sm"
    //         />
    //       </div>
    //       <div className="flex flex-col mb-4">
    //         <label>
    //           {t("Register.city")}{" "}
    //           <span className="text-red-700 text-xl">*</span>
    //         </label>
    //         <Field
    //           name="city"
    //           className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
    //           value={values.city}
    //           onChange={(e) => {
    //             setFieldValue("city", e.target.value);
    //           }}
    //         />
    //         <ErrorMessage
    //           name="city"
    //           component="div"
    //           className="text-red-500 text-sm"
    //         />
    //       </div>
    //       <div className="flex items-center gap-2 -mt-1 mb-4 ">
    //         <input
    //           name="checkbox"
    //           type="checkbox"
    //           className=" w-fit"
    //           onChange={(e) => {
    //             console.log(e.target.value);
    //             setFieldValue("checkbox", e.target.value);
    //           }}
    //           required
    //         />
    //         <label htmlFor="checkbox" className="cursor-pointer text-sm">
    //           {t("Register.termsAndConditions")}{" "}
    //           <span
    //             className="text-gray-400 text-xs underline"
    //             onClick={() => setIsModalOpen(true)}
    //           >
    //             {t("Register.SeeTermsAndConditions")}
    //           </span>
    //         </label>
    //       </div>
    //       <div className="mb-10">
    //         <button
    //           type="submit"
    //           className="px-6 py-2 text-white rounded-lg bg-[#44add2] hover:bg-[#319cc4]"
    //           onClick={() => {
    //             handleSave(values);
    //           }}
    //         >
    //           {t("common.register")}
    //         </button>
    //       </div>
    //     </Form>
    //   )}
    // </Formik>
    <div>
      {i18n.language === "en" ? (
        <iframe
          src="https://hadirbox.com/event135/form?lang_button=false&lang=en"
          onScroll={true}
          className="w-full h-[800px] lg:px-12 md:px-12 px-4"
        />
      ) : (
        <iframe
          src="https://hadirbox.com/event135/form?lang_button=false&lang=ar"
          onScroll={true}
          className="w-full h-[800px] lg:px-12 md:px-12 px-4"
        />
      )}
    </div>
  );
};

export default RegisterForm;
